import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { getEsgianTheme, Stack } from '@esgian/esgianui';
import { APP_NAME } from '@constants';
import LogDrawer from '@components/Drawers/LogDrawer/LogDrawer';
import { PeriodicOverviewTooltip } from '@components/Tooltips';
import { BarChartWithCustomTooltip } from '@components/Charts';
import { LegendValue } from '@components';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { getThemeMode } from '@store/features';

let secondAccessDef = {
  tickAmount: 5,
  opposite: true,
  max: (max) => max * 1.2,

  decimalsInFloat: 0,
  labels: {
    minWidth: 40,
    maxWidth: 40,
    style: {
      fontFamily: ['Roboto', 'helvetica', 'Arial', 'sans-serif'].join(',')
    }
  }
};

function PeriodicOverviewChart({ loading, categories, series, yAxisTitle }) {
  const [showTooltip, setShowTooltip] = useState(null);
  const [logInfo, setLogInfo] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ left: 0, top: 0 });

  const themeMode = useSelector(getThemeMode);

  const theme = useMemo(() => {
    return getEsgianTheme(themeMode ? 'dark' : 'light', APP_NAME);
  }, [themeMode]);

  const handleLogClick = useCallback(
    (column) => {
      setLogInfo({ startDate: categories[column], unit: 'day' });
      setShowTooltip(null);
    },
    [categories]
  );

  const {
    palette: {
      charts: { twoColorsContrast }
    }
  } = theme;

  return (
    <>
      <Stack direction={'row'} spacing={1}>
        <LegendValue value={'Consumption'} color={{ name: 'twoColorsContrast', index: 0 }} />
        <LegendValue value={'Accumulated'} color={{ name: 'twoColorsContrast', index: 1 }} />
      </Stack>
      <BarChartWithCustomTooltip
        titleOffsetX={-10}
        chartOffsetX={20}
        yAxisTitle={yAxisTitle}
        loading={loading}
        tooltipId={'po-custom-tooltip'}
        setTooltipPosition={setTooltipPosition}
        chartType={'bar'}
        secondYAxis={secondAccessDef}
        customLegend
        colorPalette={'twoColorsContrast'}
        setShowTooltip={setShowTooltip}
        id={'periodic-overview'}
        categories={categories}
        showTooltip={showTooltip}
        series={series}
        customTooltip={
          <PeriodicOverviewTooltip
            unit={yAxisTitle}
            setShowTooltip={setShowTooltip}
            series={series}
            legendColors={twoColorsContrast}
            date={`${moment(categories[showTooltip]).format('DD/MMM/YYYY')}`}
            handleLogClick={handleLogClick}
            column={showTooltip}
            position={tooltipPosition}
          />
        }
      />
      <LogDrawer handleClose={() => setLogInfo(null)} open={!!logInfo} logInfo={logInfo} />
    </>
  );
}

PeriodicOverviewChart.propTypes = {
  yAxisTitle: PropTypes.string,
  loading: PropTypes.bool,
  categories: PropTypes.arrayOf(PropTypes.string),
  series: PropTypes.arrayOf(PropTypes.object)
};
PeriodicOverviewChart.defaultProps = {
  yAxisTitle: '',
  categories: [],
  series: [],
  loading: false
};
export default PeriodicOverviewChart;
