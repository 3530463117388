import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Divider, Stack } from '@esgian/esgianui';
import KPIChangeDisplay from '@components/Display/KPIChangeDisplay/KPIChangeDisplay';
import { getPercentDiff } from '@helpers';
import { useSelector } from 'react-redux';
import { getDisplayUnit } from '@store/features';
import { useDisplayUnit } from '@hooks';

function DrillingActivitiesKpis({ mainData, baselineData, loading }) {
  const displayUnit = useSelector(getDisplayUnit);
  const { getKpiDisplayText, valueWithUnit } = useDisplayUnit();

  const getSumData = (main, baseline, activity) => {
    const key = `drilling${displayUnit}`;
    let mainData =
      main.find((item) => item.activity === activity)?.drillingActivityEvents[key] || {};
    let baseData =
      baseline.find((item) => item.activity === activity)?.drillingActivityEvents[key] || {};

    let mainTot = 0;
    let baseTot = 0;
    Object.values(mainData).forEach((value) => {
      mainTot += parseFloat(value);
    });
    Object.values(baseData).forEach((value) => {
      baseTot += parseFloat(value);
    });

    return {
      ...valueWithUnit(mainTot - baseTot, displayUnit, true),
      percent: getPercentDiff(mainTot, baseTot)
    };
  };

  const kpis = useMemo(() => {
    if (!mainData || !baselineData) return {};
    let mainSum = 0;
    let baseSum = 0;
    const key = `drilling${displayUnit}`;
    mainData.forEach((item) => {
      Object.values(item.drillingActivityEvents[key]).forEach((value) => {
        mainSum += parseFloat(value);
      });
    });
    baselineData.forEach((item) => {
      Object.values(item.drillingActivityEvents[key]).forEach((value) => {
        baseSum += parseFloat(value);
      });
    });

    let drilling = getSumData(mainData, baselineData, 'Drilling');
    let tripping = getSumData(mainData, baselineData, 'Tripping');
    let casing = getSumData(mainData, baselineData, 'Run casing');
    let reaming = getSumData(mainData, baselineData, 'Reaming');

    return {
      total: {
        ...valueWithUnit(mainSum - baseSum, displayUnit, true),
        percent: getPercentDiff(mainSum, baseSum)
      },
      drilling: {
        ...drilling
      },
      tripping: {
        ...tripping
      },
      casing: {
        ...casing
      },
      reaming: {
        ...reaming
      }
    };
  }, [mainData, baselineData, displayUnit]);

  return (
    <Stack
      direction={'row'}
      justifyContent={'space-evenly'}
      divider={<Divider sx={{ height: 'inherit' }} orientation={'vertical'} />}>
      <KPIChangeDisplay
        loading={loading}
        percentChange={kpis.total?.percent}
        value={kpis.total?.value ?? null}
        title={getKpiDisplayText(kpis.total?.title, 'Total')}
      />
      <KPIChangeDisplay
        loading={loading}
        percentChange={kpis.drilling?.percent}
        value={kpis.drilling?.value ?? null}
        title={`Drilling (${kpis.drilling?.title || '-'})`}
      />
      <KPIChangeDisplay
        loading={loading}
        percentChange={kpis.tripping?.percent}
        value={kpis.tripping?.value ?? null}
        title={`Tipping (${kpis.tripping?.title || '-'})`}
      />
      <KPIChangeDisplay
        loading={loading}
        percentChange={kpis.casing?.percent}
        value={kpis.casing?.value ?? null}
        title={`Run casing (${kpis.casing?.title || '-'})`}
      />
      <KPIChangeDisplay
        loading={loading}
        percentChange={kpis.reaming?.percent}
        value={kpis.reaming?.value ?? null}
        title={`Reaming (${kpis.reaming?.title || '-'})`}
      />
    </Stack>
  );
}

DrillingActivitiesKpis.propTypes = {
  mainData: PropTypes.arrayOf(PropTypes.object),
  baselineData: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool
};

DrillingActivitiesKpis.defaultProps = {
  mainData: [],
  baselineData: [],
  loading: false
};

export default DrillingActivitiesKpis;
