import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@esgian/esgianui';
import { BarChartWithCustomTooltip } from '@components/Charts';
import { PeriodicOverviewTooltip } from '@components/Tooltips';
import ViewBySelect from '@components/Inputs/ViewBySelect/ViewBySelect';
import moment from 'moment-timezone';
import { useTheme } from '@hooks';

function PeriodicOverviewWithDetailsChart({
  primaryCategories,
  primarySeries,
  loading,
  handleLogClick,
  secondaryCategories,
  secondaryChartStacked,
  secondarySeries,
  secondaryChartType,
  selectedView,
  loadingSecondarySeries,
  selectedColumn,
  secondaryUnit,
  setSelectedColumn,
  handleViewChange,
  unit,
  detailsTitle,
  colorPalette,
  secondaryColorPalette,
  xAxisType,
  secondaryPrimaryChart
  // legend,
}) {
  const [primaryTooltipPosition, setPrimaryTooltipPosition] = useState({ left: 0, top: 0 });
  const [secondaryTooltipPosition, setSecondaryTooltipPosition] = useState({ left: 0, top: 0 });
  const [showPrimaryTooltip, setPrimaryShowTooltip] = useState(null);
  const [secondaryShowTooltip, setSecondaryShowTooltip] = useState(null);
  const {
    theme: {
      palette: { charts }
    }
  } = useTheme();

  const logClick = useCallback(
    (column, isPrimary) => {
      setSecondaryShowTooltip(null);
      setPrimaryShowTooltip(null);
      let date = isPrimary ? primaryCategories[column] : secondaryCategories[column];
      handleLogClick({ startDate: date, unit: isPrimary ? 'day' : 'hour' });
    },
    [primaryCategories, secondaryCategories]
  );

  return (
    <Stack spacing={2}>
      <Typography variant={'h6'}>Periodic overview</Typography>
      {/* {legend} */}
      <BarChartWithCustomTooltip
        yAxisTitle={unit}
        loading={loading}
        handleColumnClick={setSelectedColumn}
        stacked
        tooltipId={'po-custom-tooltip'}
        setTooltipPosition={setPrimaryTooltipPosition}
        colorPalette={colorPalette}
        setShowTooltip={setPrimaryShowTooltip}
        id={'operations-periodic-overview'}
        categories={primaryCategories}
        showTooltip={showPrimaryTooltip}
        series={primarySeries}
        showLegend
        detailsTitle={detailsTitle === 'Power details' ? 'Equipment periodic' : ''}
        customTooltip={
          <PeriodicOverviewTooltip
            unit={unit}
            setShowTooltip={setPrimaryShowTooltip}
            series={primarySeries}
            legendColors={typeof colorPalette === 'string' ? charts[colorPalette] : colorPalette}
            date={`${moment(primaryCategories[showPrimaryTooltip]).format('DD/MMM/YYYY')}`}
            handleLogClick={(column) => logClick(column, true)}
            column={showPrimaryTooltip}
            position={primaryTooltipPosition}
          />
        }
      />
      {secondaryPrimaryChart}
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography sx={{ letterSpacing: '0.15px' }} variant={'subtitle1'}>
          {detailsTitle} - {`${moment(primaryCategories[selectedColumn]).format('DD/MMM/YYYY')}`}
        </Typography>
        {handleViewChange && (
          <ViewBySelect
            selectedView={selectedView}
            handleChange={handleViewChange}
            disabled={!handleViewChange}
          />
        )}
      </Stack>
      <BarChartWithCustomTooltip
        showLegend
        xAxisType={xAxisType}
        yAxisTitle={secondaryUnit || unit}
        customCategoryRender={() =>
          secondaryCategories?.map((item, i) => {
            const dt = moment.utc(item);
            if (selectedView === 'min') {
              if (dt.minutes() === 0) {
                return dt.format('HH:mm');
              } else {
                return '';
              }
            }
            if (dt.hours() === 0 && dt.minutes() === 0 && dt.seconds() === 0) {
              return dt.format('DD MMM');
            }
            if (i % 2 === 0) {
              return dt.format('HH:mm');
            }
            return '';
          })
        }
        loading={loading || loadingSecondarySeries}
        stacked={secondaryChartStacked}
        chartType={secondaryChartType}
        detailsTitle={detailsTitle}
        tooltipId={'details-po-custom-tooltip'}
        setTooltipPosition={setSecondaryTooltipPosition}
        colorPalette={
          typeof colorPalette === 'string' ? charts[colorPalette] : secondaryColorPalette
        }
        setShowTooltip={setSecondaryShowTooltip}
        id={'details-operations-periodic-overview'}
        categories={secondaryCategories}
        showTooltip={secondaryShowTooltip}
        series={secondarySeries}
        customTooltip={
          <PeriodicOverviewTooltip
            unit={secondaryUnit || unit}
            id={'details-po-custom-tooltip'}
            setShowTooltip={setSecondaryShowTooltip}
            series={secondarySeries}
            legendColors={
              typeof colorPalette === 'string' ? charts[colorPalette] : secondaryColorPalette
            }
            date={`${moment(secondaryCategories[secondaryShowTooltip])
              .utc()
              .format('DD/MMM/YYYY HH:mm')}`}
            handleLogClick={(column) => logClick(column, false)}
            column={secondaryShowTooltip}
            position={secondaryTooltipPosition}
          />
        }
      />
    </Stack>
  );
}

PeriodicOverviewWithDetailsChart.propTypes = {
  unit: PropTypes.string,
  secondaryUnit: PropTypes.string,
  selectedColumn: PropTypes.number,
  secondaryPrimaryChart: PropTypes.node,
  legend: PropTypes.node,
  setSelectedColumn: PropTypes.func.isRequired,
  handleViewChange: PropTypes.func,
  handleLogClick: PropTypes.func.isRequired,
  secondaryChartType: PropTypes.oneOf(['line', 'bar']),
  secondaryColorPalette: PropTypes.arrayOf(PropTypes.string),
  primaryCategories: PropTypes.arrayOf(PropTypes.string),
  primarySeries: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.number])),
  secondaryCategories: PropTypes.arrayOf(PropTypes.string),
  selectedView: PropTypes.oneOf(['hour', 'min']),
  secondaryChartStacked: PropTypes.bool,
  loading: PropTypes.bool,
  colorPalette: PropTypes.any,
  detailsTitle: PropTypes.string,
  loadingSecondarySeries: PropTypes.bool,
  secondarySeries: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.number])),
  xAxisType: PropTypes.string
};

PeriodicOverviewWithDetailsChart.defaultProps = {
  primaryCategories: [],
  primarySeries: [],
  secondaryCategories: [],
  legend: null,
  secondaryUnit: null,
  secondaryColorPalette: null,
  secondaryPrimaryChart: undefined,
  unit: '',
  secondaryChartStacked: false,
  loading: false,
  loadingSecondarySeries: false,
  handleViewChange: undefined,
  secondaryChartType: 'bar',
  detailsTitle: 'Details',
  colorPalette: 'fourColorsStacked',
  selectedView: 'hour',
  secondarySeries: [],
  selectedColumn: 0,
  xAxisType: 'category'
};

export default PeriodicOverviewWithDetailsChart;
