import { BaseApiRequest } from '@api/BaseApi';
import { getToken } from '@store/helpers';

export const createBaseline = (body) => {
  return BaseApiRequest(null, `/Baseline/Create`, 'gpr', 'POST', {
    token: getToken(),
    ...body
  });
};

export const updateBaseline = (body) => {
  return BaseApiRequest(null, `/Baseline/Update`, 'gpr', 'PUT', {
    token: getToken(),
    ...body
  });
};
export const deleteBaseline = ({ baselineId, token }) => {
  return BaseApiRequest(null, `/Baseline/Delete`, 'gpr', 'DELETE', {
    token: getToken() || token,
    baselineId: baselineId
  });
};
export const getBaselineViewSettings = (signal, { userId, token }) => {
  return BaseApiRequest(signal, `/BaselineViewSettings/Get`, 'gpr', 'POST', {
    token: getToken() || token,
    userId: userId
  });
};
export const createBaselineViewSettings = (body) => {
  return BaseApiRequest(null, `/BaselineViewSettings/Create`, 'gpr', 'POST', body);
};
export const updateBaselineViewSettings = (body) => {
  let newBody = { ...body };
  newBody.baselineViewSettingsId = body.graphDisplays[0].viewSettingsId;

  return BaseApiRequest(null, `/BaselineViewSettings/Update`, 'gpr', 'PUT', newBody);
};
