import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Paper, Stack, TableCell, TableRow, Typography } from '@esgian/esgianui';
import moment from 'moment-timezone';
import { fetchWarningData } from '@api/Rig';
import { API_ROLLUP, WARNINGS_TYPE, TIMESTAMP } from '@constants';
import { useSelector } from 'react-redux';
import {
  EngineAverageChartsWrapper,
  WarningPowerDetailsChart
} from '@components/SubSections/Analytics';
import WarningInfoBoxes from '@components/Tables/FocusAreasTable/WarningInfoBoxes';
import { getMainPageSlice } from '@store/features/filters/MainPageSlice/MainPageSlice';
import { useTimezone } from '@hooks/useTimezone';
import { useQuery } from '@tanstack/react-query';

const standardizeResponse = (d, type) => {
  const { clarifyData: data, numOfEngines } = d;
  if (type === WARNINGS_TYPE.TooManyHPU) {
    return {
      peakPower: data.HPUP_available || [],
      avgPowerProd: data.HPUP || [],
      totPowerAvailable: data.OptHPU_available || [],
      optPowerAvailable: data.OptHPUP || [],
      engOn: data.HPUr || [],
      optEngOn: data.HPU_req || [],
      numOfEngines: numOfEngines
    };
  }
  if (type === WARNINGS_TYPE.TooManyEng) {
    let obj = {
      peakPower: data.P_peak || [],
      avgPowerProd: data.P_produced || [],
      totPowerAvailable: data.P_available || [],
      optPowerAvailable: data.P_available_sim || [],
      engOn: data.EngOn || [],
      optEngOn: data.EngReq || [],
      numOfEngines: numOfEngines,
      EngLsim: data.EngLsim || [],
      OptRigSFOC: data.OptRigSFOC || []
    };
    for (let i = 1; i <= numOfEngines; i++) {
      obj[`Eng${i}Lavg`] = data[`Eng${i}Lavg`];
      obj[`Eng${i}SFOC`] = data[`Eng${i}SFOC`];
    }

    return obj;
  }
  return {};
};

function WarningDetailsView({ rowData, numColumns }) {
  const { selectedRig, startDate, endDate } = useSelector(getMainPageSlice);
  const { calculateDatesWithPadding } = useTimezone();
  const powerChartRef = useRef(null);
  const onlineChartRef = useRef(null);
  const avgLoadChartRef = useRef(null);
  const avgSfocChartRef = useRef(null);

  const chartQuery = useQuery({
    queryKey: ['analytics', 'table', { row: rowData }],
    enabled: [WARNINGS_TYPE.TooManyHPU, WARNINGS_TYPE.TooManyEng].includes(rowData?.type),
    placeholderData: null,
    queryFn: ({ signal }) => {
      let focusAreaStartDate = moment
        .parseZone(rowData.startDate)
        .subtract(30, 'minute')
        .format(TIMESTAMP);

      let focusAreaEndDate = moment.parseZone(rowData.endDate).add(30, 'minute').format(TIMESTAMP);

      const { dateStart: sDate, dateEnd: eDate } = calculateDatesWithPadding(startDate, endDate, {
        value: 30,
        unit: 'minutes'
      });

      return fetchWarningData(
        signal,
        selectedRig,
        sDate,
        eDate,
        focusAreaStartDate,
        focusAreaEndDate,
        API_ROLLUP.MIN,
        rowData.type
      )
        .then((result) => standardizeResponse(result, rowData.type))
        .catch(() => null);
    }
  });

  return (
    <TableRow>
      <TableCell
        sx={{ background: ({ palette }) => `${palette.background.default} !important` }}
        colSpan={numColumns}>
        <Paper sx={{ pb: 2, background: ({ palette }) => `${palette.background.default}` }}>
          <Stack spacing={2}>
            <Typography variant={'h6'}>Details analytics</Typography>
            <WarningInfoBoxes data={rowData} />
            {[WARNINGS_TYPE.TooManyHPU, WARNINGS_TYPE.TooManyEng].includes(rowData.type) && (
              <WarningPowerDetailsChart
                powerSyncRefs={[avgSfocChartRef, avgLoadChartRef, onlineChartRef]}
                onlineSyncRefs={[avgSfocChartRef, avgLoadChartRef, powerChartRef]}
                powerChartRef={powerChartRef}
                onlineChartRef={onlineChartRef}
                primaryTitle={`${
                  rowData.type === WARNINGS_TYPE.TooManyHPU ? 'HPU p' : 'P'
                }ower details`}
                secondaryTitle={
                  rowData.type === WARNINGS_TYPE.TooManyHPU ? 'Online HPU' : 'Online engine'
                }
                unit={'kW'}
                detailsData={chartQuery.data}
                rowData={rowData}
                loading={chartQuery.isFetching}
              />
            )}
            {rowData.type === WARNINGS_TYPE.TooManyEng && (
              <EngineAverageChartsWrapper
                avgLoadSyncRefs={[avgSfocChartRef, powerChartRef, onlineChartRef]}
                avgSfocSyncRefs={[avgLoadChartRef, powerChartRef, onlineChartRef]}
                avgLoadChartRef={avgLoadChartRef}
                avgSfocChartRef={avgSfocChartRef}
                rowData={rowData}
                loading={chartQuery.isFetching}
                chartData={chartQuery.data}
              />
            )}
          </Stack>
        </Paper>
      </TableCell>
    </TableRow>
  );
}

WarningDetailsView.propTypes = {
  rowData: PropTypes.object,
  numColumns: PropTypes.number
};

WarningDetailsView.defaultProps = {
  rowData: null,
  numColumns: 8
};

export default WarningDetailsView;
