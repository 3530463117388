import moment from 'moment';
import { TOOLTIP_DATE_READABLE } from '@constants';
import { parseNumberToSignificant } from '@helpers';

export const getOrCreateTooltipDiv = (chart, theme, withButton) => {
  const {
    palette: {
      tooltip: { background, contrastText }
    }
  } = theme;
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.background = background;
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.color = contrastText;
    tooltipEl.style.opacity = 1;
    tooltipEl.style.visibility = 'visible';
    tooltipEl.style.fontSize = '14px';
    tooltipEl.style.pointerEvents = withButton ? 'auto' : 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.textWrap = 'nowrap';
    tooltipEl.style.padding = '8px 16px';
    tooltipEl.style.minWidth = '300px';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';

    const body = document.createElement('div');
    body.style.display = 'flex';
    body.style.flexDirection = 'column';
    body.style.gap = '8px';

    tooltipEl.appendChild(body);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const showSyncTooltip = (chartRef, index) => {
  const chart = chartRef.current;
  if (!chart) return;
  const tooltip = chart.tooltip;
  const element = chart.getDatasetMeta(0).data[index];
  if (element) {
    tooltip.setActiveElements([{ datasetIndex: 0, index }], {
      x: element.x,
      y: element.y
    });
  }
};

export const createTooltipLabelText = (labelText) => {
  const label = document.createElement('span');
  label.textContent = `${labelText}:`;
  label.style.fontFamily = '"Noto Sans", Roboto, helvetica, Arial, sans-serif';
  label.style.fontSize = '0.75rem';
  label.style.lineHeight = '1.66';
  return label;
};

export const createTooltipValueText = (textValue) => {
  const text = document.createElement('p');
  text.textContent = textValue;
  text.style.fontFamily = '"Noto Sans", Roboto, helvetica, Arial, sans-serif';
  text.style.fontSize = '0.875rem';
  text.style.margin = '0';
  text.style.lineHeight = '1.43';
  return text;
};

export const defaultTooltip = (
  context,
  data,
  theme,
  unit,
  hideCurrentTooltip,
  syncRefs,
  handleLogClick = undefined
) => {
  if (!context) return;

  const {
    palette: {
      tooltip: { contrastText }
    }
  } = theme;
  const { chart, tooltip } = context;
  const dataIndex = tooltip.dataPoints?.[0]?.dataIndex;
  if (hideCurrentTooltip) {
    syncRefs?.forEach((reference) => {
      showSyncTooltip(reference, dataIndex);
    });
    return;
  }

  const tooltipEl = getOrCreateTooltipDiv(chart, theme, !!handleLogClick);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    tooltipEl.style.visibility = 'hidden';
    return;
  }

  if (tooltip.body) {
    const title = tooltip.title || [];
    const header = document.createElement('div');
    header.style.fontWeight = 'bold';
    header.style.fontSize = '16px';
    header.textContent = moment.utc(parseInt(title)).format(TOOLTIP_DATE_READABLE);

    const tableBody = document.createElement('div');

    // Create content for each point and line
    data?.datasets?.forEach(({ data, label, borderColor, hidden, unit: hiddenUnit }) => {
      const content = document.createElement('div');
      content.style.display = 'flex';
      content.style.justifyContent = 'space-between';
      content.style.gap = '16px';

      const legend = document.createElement('div');
      legend.style.display = 'flex';
      legend.style.gap = '8px';
      legend.style.alignItems = 'center';
      legend.style.flexDirection = 'row';

      const legendBox = document.createElement('span');
      legendBox.style.background = borderColor;
      legendBox.style.height = '8px';
      legendBox.style.width = '8px';
      legendBox.style.display = 'inline-block';
      const title = createTooltipLabelText(label);

      let value = data[dataIndex].y;
      let valueText;

      if (value) {
        valueText = createTooltipValueText(
          `${parseNumberToSignificant(parseFloat(value))} ${hidden ? hiddenUnit : unit}`
        );
      } else {
        valueText = createTooltipValueText('-');
      }

      legend.appendChild(legendBox);
      legend.appendChild(title);
      content.appendChild(legend);
      content.appendChild(valueText);
      tableBody.appendChild(content);
    });

    if (handleLogClick) {
      // Add the button to the tooltip
      const buttonContainer = document.createElement('div');
      buttonContainer.style.display = 'flex';
      buttonContainer.style.width = '100%';
      buttonContainer.style.placeContent = 'center';
      buttonContainer.style.flexDirection = 'row';
      const button = document.createElement('button');
      button.textContent = 'SEE LOG';
      button.style.marginTop = '10px'; // Add spacing to separate from content
      button.style.padding = '8px 16px';
      button.style.border = '1px solid';
      button.style.borderColor = contrastText;
      button.style.background = 'transparent';
      button.style.borderRadius = '100px';
      button.style.color = contrastText;
      button.style.cursor = 'pointer';
      button.style.fontSize = '14px';
      button.style.minWidth = '63px'; // Make the button span the width of the tooltip
      button.style.textAlign = 'center';

      // Add event listener if handleLogClick is provided
      button.addEventListener('click', () => handleLogClick(title[0]));

      // Append the button to the tooltip
      buttonContainer.appendChild(button);
      tableBody.appendChild(buttonContainer);
    }

    const tableRoot = tooltipEl.querySelector('div');

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(header);
    tableRoot.appendChild(tableBody);
  }

  // Positioning the tooltip
  let { offsetLeft: positionX } = chart.canvas;

  let left = positionX + tooltip.caretX;
  let halfWidth = chart.canvas.width / 2;

  if (left < halfWidth) {
    tooltipEl.style.left = `${left - 20 + tooltipEl.getBoundingClientRect().width}px`;
  } else {
    tooltipEl.style.left = `${left + 20 - tooltipEl.getBoundingClientRect().width}px`;
  }

  // tooltipEl.style.top = `${positionY + tooltip.caretY}px`;
  tooltipEl.style.top = `-${tooltipEl.getBoundingClientRect().height / 2}px`;

  syncRefs?.forEach((reference) => {
    showSyncTooltip(reference, dataIndex);
  });

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.visibility = 'visible';
  tooltipEl.style.position = 'absolute'; // Ensure absolute positioning
  tooltipEl.style.zIndex = '2000'; // Ensure it stays on top

  if (handleLogClick) {
    tooltipEl.style.left = `${left}px`;

    tooltipEl.style.top = `-${tooltipEl.getBoundingClientRect().height / 2}px`;

    tooltipEl.addEventListener('mouseout', () => {
      tooltipEl.style.opacity = 0;
      tooltipEl.style.visibility = 'hidden';
    });

    tooltipEl.addEventListener('mouseover', () => {
      tooltipEl.style.visibility = 'visible';
      tooltipEl.style.opacity = 1;
    });
  }
};
