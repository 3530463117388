import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Chart } from 'react-chartjs-2';
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  Tooltip
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { useTheme } from '@hooks';
import { defaultTooltip } from '@components/Charts/ChartJsTooltipHelpers';
import { hoverLinePlugin, syncTooltipHiderPlugin, yAxisTitle } from '@components/ChartJsPlugins';
import { Box, Stack } from '@esgian/esgianui';
import { LegendValue } from '@components';
import moment from 'moment/moment';
import Chartjs from '@components/Charts/Chartjs';

// Register Chart.js components
ChartJS.register(
  LineElement,
  PointElement,
  LineController,
  BarElement,
  BarController,
  CategoryScale,
  TimeScale,
  LinearScale,
  Filler,
  Tooltip,
  zoomPlugin
);
let plugins = [hoverLinePlugin, yAxisTitle];

const getOptions = (
  theme,
  data,
  syncRefs,
  max,
  min,
  unit,
  stackedBar,
  type,
  hideCurrentTooltip,
  customTooltipHandler,
  handleLogClick
) => {
  const {
    palette: {
      text: { primary },
      neutral: { neutral04 }
    }
  } = theme;
  return {
    commonUpdate: true,
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 30
      }
    },
    scales: {
      x: {
        min: 0,
        offset: false,
        type: 'category',
        labels: data?.labels ?? [],
        ticks: {
          callback: function (index) {
            let currentDate = moment.utc(this.getLabelForValue(index));
            if (index >= data?.labels.length) {
              currentDate.format('MMM DD');
            }
            let nextDate = moment.utc(this.getLabelForValue(index + 1));
            let rollup = 'hour';
            if (nextDate.diff(currentDate, 'hours') < 1) {
              rollup = 'min';
            }

            if (currentDate.clone().hour() === 0 && rollup === 'hour') {
              return currentDate.format('MMM DD');
            }
            if (currentDate.clone().startOf('day').isSame(currentDate) && rollup === 'min') {
              return currentDate.format('MMM DD');
            }
            return currentDate.format('HH:mm');
          },
          color: primary,
          maxRotation: 45,
          minRotation: 45,
          font: {
            size: 10,
            family: ['Roboto', 'helvetica', 'Arial', 'sans-serif'].join(',')
          }
        },
        grid: {
          display: false,
          drawOnChartArea: false,
          drawTicks: false
        }
      },
      y: {
        stacked: type === 'bar' && stackedBar,
        border: {
          display: false
        },
        grid: {
          tickLength: 0,
          color: `${neutral04}7F`
        },
        afterFit: function (scaleInstance) {
          let stepSize = `${Math.round((max * 1.5) / 5) * 4}`.length;
          let size = stepSize < 4 ? 4 : stepSize;
          scaleInstance.width = size * 8; // sets the width to 100px
        },
        ticks: {
          color: primary,
          font: {
            size: 10,
            family: ['Roboto', 'helvetica', 'Arial', 'sans-serif'].join(',')
          },
          beginAtZero: true,
          callback: (val) => parseInt(val),
          maxTicksLimit: 6, // +1 for zero
          stepSize: Math.round(max / 5),
          max: max,
          count: 6,
          precision: 0,
          xMin: 0,
          autoSkip: false
        },
        min: min,
        max: max,
        type: 'linear',
        position: 'left',
        beginAtZero: true
      }
    },
    elements: {
      line: {
        tension: 0
      },
      point: {
        radius: 0
      }
    },
    tooltips: {
      mode: 'index',
      intersect: false
    },
    plugins: {
      tooltip: {
        callbacks: {},
        enabled: false,
        interaction: {
          intersect: false,
          mode: 'index'
        },
        position: 'nearest',
        external: (context) =>
          customTooltipHandler
            ? customTooltipHandler(context, data)
            : defaultTooltip(
                context,
                data,
                theme,
                unit,
                hideCurrentTooltip,
                syncRefs,
                handleLogClick
              )
      },
      yAxisTitle: {
        fontColor: primary,
        fontSize: '12px',
        text: unit
      },
      hoverLinePlugin: {
        syncRefs: syncRefs,
        lineColor: primary
      },
      zoom: {
        pan: {
          onPan({ chart }) {
            syncRefs.forEach(({ current }) =>
              current?.zoomScale(
                'x',
                { min: Math.trunc(chart.scales.x.min), max: Math.trunc(chart.scales.x.max) },
                'none'
              )
            );
          },
          enabled: true,
          mode: 'x'
        },
        limits: {
          x: { min: 'original', max: 'original', minRange: 10 }
        },
        zoom: {
          mode: 'x',
          onZoom({ chart }) {
            if (chart.scales.max < 10) {
              chart.scales.x.max = 10;
            }
            syncRefs.forEach(({ current }) =>
              current?.zoomScale(
                'x',
                { min: Math.trunc(chart.scales.x.min), max: Math.trunc(chart.scales.x.max) },
                'none'
              )
            );
          },
          drag: {
            enabled: false
          },
          pinch: {
            enabled: true
          },
          wheel: {
            enabled: true,
            modifierKey: 'ctrl'
          }
        }
      }
    }
  };
};

function CanvasTimeSeriesChart({
  syncRefs,
  id,
  series,
  loading,
  unit,
  maxY,
  minY,
  chartRef,
  hideCurrentTooltip,
  customTooltipHandler,
  handleLogClick,
  height,
  type,
  stackedBar
}) {
  const [legendActive, setLegendActive] = useState([]);
  const { theme } = useTheme();
  const {
    palette: {
      charts: { sixColorsLine }
    }
  } = theme;

  if (syncRefs.length) {
    plugins.push(syncTooltipHiderPlugin(syncRefs));
  }

  const data = useMemo(() => {
    if (!series.length) return { labels: [], datasets: [] };
    return {
      labels: series[0].data?.map(({ x }) => x),
      datasets: series.map((val, i) => ({
        borderColor: sixColorsLine[i % sixColorsLine.length], // Use modulo to avoid index out of bounds
        borderWidth: 2,
        spanGaps: true,
        pointHitRadius: 0,
        pointHoverRadius: 0,
        tension: 0,
        ...val,
        label: val.label ?? '', // Ensure each dataset has a label
        data: val.data ?? [] // Pass data correctly
      }))
    };
  }, [series, sixColorsLine]);

  const options = useMemo(() => {
    let maxYaxis = maxY;
    if (!maxYaxis) {
      maxYaxis = Math.max(...series.flatMap((s) => s.data.map((d) => d.y))) * 1.2;
    }
    return getOptions(
      theme,
      data,
      syncRefs,
      maxYaxis,
      minY,
      unit,
      stackedBar,
      type,
      hideCurrentTooltip,
      customTooltipHandler,
      handleLogClick
    );
  }, [data, theme, syncRefs]);

  const handleLegendClick = (index) => {
    const chart = chartRef.current;
    if (chartRef.current) {
      const meta = chart.getDatasetMeta(index);
      let value = !chart.data.datasets[index].hidden;
      meta.hidden = meta.hidden === null ? value : null;
      if (meta.hidden) {
        setLegendActive([...legendActive, index]);
      } else {
        setLegendActive(() => [...legendActive.filter((v) => v !== index)]);
      }
      chart.update();
    }
  };

  return (
    <Stack sx={{ width: 'auto' }}>
      <Stack direction={'row'} spacing={2}>
        {[...data.datasets]
          .filter(({ hidden }) => !hidden)
          .map(({ label, borderColor }, i) => (
            <LegendValue
              inactive={legendActive.includes(i)}
              onClick={() => handleLegendClick(i)}
              key={`${id}-legend-${i}`}
              value={label}
              color={borderColor}
            />
          ))}
      </Stack>
      <Box sx={{ height: height, width: '100%', position: 'relative' }}>
        <Chartjs
          loading={loading}
          plugins={plugins}
          chartRef={chartRef}
          id={id}
          type={type}
          data={data}
          options={options}
        />
      </Box>
    </Stack>
  );
}

CanvasTimeSeriesChart.propTypes = {
  loading: PropTypes.bool,
  stackedBar: PropTypes.bool,
  type: PropTypes.oneOf(['line', 'bar']),
  hideCurrentTooltip: PropTypes.bool,
  height: PropTypes.string,
  maxY: PropTypes.number,
  minY: PropTypes.number,
  unit: PropTypes.string,
  series: PropTypes.arrayOf(PropTypes.object),
  id: PropTypes.string,
  chartRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  syncRefs: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })])
  ),
  customTooltipHandler: PropTypes.func,
  handleLogClick: PropTypes.func
};

CanvasTimeSeriesChart.defaultProps = {
  height: '330px',
  unit: '',
  maxY: undefined,
  stackedBar: false,
  type: 'line',
  minY: 0,
  loading: false,
  series: [],
  syncRefs: [],
  id: 'timeSeries',
  chartRef: undefined,
  hideCurrentTooltip: false,
  handleLogClick: undefined,
  customTooltipHandler: undefined
};

export default CanvasTimeSeriesChart;
