import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { BarChartWithCustomTooltip } from '@components/Charts';
import { PeriodicOverviewTooltip } from '@components/Tooltips';
import moment from 'moment-timezone';
import { useDisplayUnit, useTheme } from '@hooks';
import { Stack, Typography } from '@esgian/esgianui';
import { LegendValue, TextWithTooltipIcon } from '@components';
import { DISPLAY_UNIT } from '@constants';
import { parseNumberToSignificant } from '@helpers';

let tooltipText = (
  <Stack spacing={2}>
    <Typography variant={'caption'}>
      Total regenerated energy from the drilling VFD&apos;s burned or reused.
    </Typography>
    <Typography variant={'caption'}>
      The reused energy is consumed by the other drives on the VFD switchboard and is considered as
      free energy.
    </Typography>
    <Typography variant={'caption'}>
      The burned energy is wasted energy because it is burned over the resistor banks via the brake
      choppers on the switchboard.
    </Typography>
    <Typography variant={'caption'}>
      For context, clicking on one of the bars will reveal the details per hour for the day before,
      selected day and the next day for the Equipment in the Power details graph below.
    </Typography>
  </Stack>
);

function DailyRegeneratedEnergyDrawworks({
  equipmentData,
  setSelectedColumn,
  loading,
  categories
}) {
  const { getUnitTitle, convertUnitValue } = useDisplayUnit();
  const [tooltipPosition, setTooltipPosition] = useState({ left: 0, top: 0 });
  const [showTooltip, setShowTooltip] = useState(null);
  const {
    theme: { palette }
  } = useTheme();

  const colorPalette = useMemo(() => {
    return [palette.success.main, palette.error.main];
  }, [palette]);

  const { unit, series } = useMemo(() => {
    let displayUnit = DISPLAY_UNIT.ENERGY;
    if (!equipmentData) return {};
    const { clarifyData } = equipmentData;
    const { DC_Break_Res_Energy, VFD_Reused_Energy } = clarifyData;

    let reused = { default: [], converted: [] };
    let burned = { default: [], converted: [] };
    let max = 0;
    Object.values(VFD_Reused_Energy).forEach((val) => {
      let value = parseFloat(val);
      max = max < value ? value : max;
      reused.default.push(parseNumberToSignificant(value));
      reused.converted.push(convertUnitValue(value, displayUnit));
    });

    Object.values(DC_Break_Res_Energy).forEach((val) => {
      let value = parseFloat(val);
      max = max < value ? value : max;
      burned.default.push(parseNumberToSignificant(value));
      burned.converted.push(convertUnitValue(value, displayUnit));
    });

    const { title, converted } = getUnitTitle(max, displayUnit);
    return {
      series: [
        { name: 'Reused', data: converted ? reused.converted : reused.default },
        { name: 'Burned', data: converted ? burned.converted : burned.default }
      ],
      unit: title
    };
  }, [equipmentData]);

  return (
    <Stack>
      <Stack spacing={2}>
        <Typography variant={'subtitle1'}>Daily drilling VFD regenerated energy</Typography>
        <Stack direction={'row'} spacing={1}>
          <LegendValue value={'Reused'} color={palette.success.main} />
          <TextWithTooltipIcon
            iconSize={'16px'}
            gap={'4px'}
            label={<LegendValue value={'Burned'} color={palette.error.main} />}
            tooltipText={tooltipText}
          />
        </Stack>
      </Stack>
      <BarChartWithCustomTooltip
        height={300}
        titleOffsetX={25}
        yAxisTitle={unit}
        loading={loading}
        handleColumnClick={setSelectedColumn}
        stacked
        tooltipId={'regenerated-energy-tooltip'}
        setTooltipPosition={setTooltipPosition}
        colorPalette={colorPalette}
        setShowTooltip={setShowTooltip}
        id={'regenerated-energy-chart'}
        categories={categories}
        showTooltip={showTooltip}
        series={series}
        detailsTitle={'Power details'}
        customTooltip={
          <PeriodicOverviewTooltip
            unit={unit}
            setShowTooltip={setShowTooltip}
            series={series}
            legendColors={colorPalette}
            date={`${moment(categories[showTooltip]).format('DD/MMM/YYYY')}`}
            column={showTooltip}
            position={tooltipPosition}
          />
        }
      />
    </Stack>
  );
}

DailyRegeneratedEnergyDrawworks.propTypes = {
  equipmentData: PropTypes.object,
  setSelectedColumn: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  categories: PropTypes.arrayOf(PropTypes.string)
};

DailyRegeneratedEnergyDrawworks.defaultProps = {
  categories: [],
  equipmentData: null,
  loading: false
};

export default DailyRegeneratedEnergyDrawworks;
