import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Skeleton, Stack, Typography } from '@esgian/esgianui';
import moment from 'moment-timezone';
import LogDrawer from '@components/Drawers/LogDrawer/LogDrawer';
import CanvasTimeSeriesChart from '@components/Charts/CanvasTimeSeriesChart/CanvasTimeSeriesChart';

function PowerDetailsCharts({
  selectedView,
  loading,
  powerPrimaryData,
  powerSecondaryData,
  selectedDate,
  engineLoadData,
  engineSfocData,
  numberOfEngines
}) {
  const [logInfo, setLogInfo] = useState(null);
  const powerMainRef = useRef();
  const powerSecRef = useRef();
  const engLoadRef = useRef();
  const engSfocRef = useRef();
  let primaryMerged = [...powerPrimaryData];

  if (powerSecondaryData[0]) {
    primaryMerged.push({ ...powerSecondaryData[0], hidden: true, unit: '' });
  }
  const handleLogClick = (data) => {
    if (!data) return;
    setLogInfo({ startDate: moment.utc(parseInt(data)), unit: 'hour' });
  };

  return (
    <>
      <div
        className={'selected-none'}
        style={{
          borderLeft: '1px dashed darkgray',
          position: 'absolute',
          height: selectedView === 'hour' ? '1060px' : '830px',
          visibility: 'hidden',
          // transition: 'all 0.2s ease',
          pointerEvents: 'none',
          zIndex: 1
        }}
        id="hover-line"
      />
      <div className={'custom-tooltip-chart hidden-x-axis-tooltip'}>
        <CanvasTimeSeriesChart
          id={'power-main'}
          handleLogClick={handleLogClick}
          syncRefs={[powerSecRef, engLoadRef, engSfocRef]}
          height={'200px'}
          unit={'kW'}
          series={primaryMerged}
          loading={loading}
          chartRef={powerMainRef}
        />
      </div>
      {!loading && selectedView !== 'min' && (
        <CanvasTimeSeriesChart
          hideCurrentTooltip
          id={'power-sec'}
          maxY={numberOfEngines}
          syncRefs={[powerMainRef, engLoadRef, engSfocRef]}
          height={'200px'}
          unit={''}
          series={powerSecondaryData}
          loading={loading}
          chartRef={powerSecRef}
        />
      )}
      <Stack spacing={2}>
        {loading ? (
          <Skeleton height={'2em'} width={'10em'} />
        ) : (
          <Typography variant={'h6'}>
            Engine average load - {moment.parseZone(selectedDate?.date).format('DD/MMM/YYYY')}
          </Typography>
        )}
        <Box className={'custom-tooltip-chart hidden-x-axis-tooltip'}>
          <CanvasTimeSeriesChart
            handleLogClick={handleLogClick}
            id={'power-avg-load'}
            maxY={100}
            syncRefs={[powerMainRef, powerSecRef, engSfocRef]}
            height={'200px'}
            unit={'%'}
            series={engineLoadData}
            loading={loading}
            chartRef={engLoadRef}
          />
        </Box>
      </Stack>
      {loading ? (
        <Skeleton height={'2em'} width={'10em'} />
      ) : (
        <Typography variant={'h6'}>
          Engine average SFOC - {moment.parseZone(selectedDate?.date).format('DD/MMM/YYYY')}
        </Typography>
      )}

      <Box className={'custom-tooltip-chart hidden-x-axis-tooltip'}>
        <CanvasTimeSeriesChart
          handleLogClick={handleLogClick}
          maxY={350}
          minY={150}
          id={'power-avg-sfoc'}
          syncRefs={[powerMainRef, powerSecRef, engLoadRef]}
          height={'200px'}
          unit={'g/kWh'}
          series={engineSfocData}
          loading={loading}
          chartRef={engSfocRef}
        />
      </Box>

      <LogDrawer handleClose={() => setLogInfo(null)} open={!!logInfo} logInfo={logInfo} />
    </>
  );
}

PowerDetailsCharts.propTypes = {
  powerSecondaryData: PropTypes.arrayOf(PropTypes.object),
  powerPrimaryData: PropTypes.arrayOf(PropTypes.object),
  engineLoadData: PropTypes.arrayOf(PropTypes.object),
  engineSfocData: PropTypes.arrayOf(PropTypes.object),
  categories: PropTypes.arrayOf(PropTypes.string),
  selectedDate: PropTypes.object,
  loading: PropTypes.bool,
  numberOfEngines: PropTypes.number,
  selectedView: PropTypes.string
};

PowerDetailsCharts.defaultProps = {
  numberOfEngines: 5,
  selectedDate: null,
  selectedView: 'hour',
  powerSecondaryData: [],
  powerPrimaryData: [],
  categories: [],
  engineLoadData: [],
  engineSfocData: [],
  loading: false
};

export default PowerDetailsCharts;
