/* eslint-disable no-prototype-builtins */
import React from 'react';
import { Grid, Stack } from '@esgian/esgianui';
import { PeriodicOverviewDetails } from '@components';
import { TotalForPeriod, TotRegeneratedEnergyDrawworks } from '@components/SubSections/Equipment';
import { useMainPageQueries } from '@hooks/usePageQueries/useMainPageQueries';

function EquipmentSection() {
  const { rigEquipmentQuery } = useMainPageQueries();

  return (
    <Grid container spacing={2}>
      <Grid item xs={3.5}>
        <Stack spacing={2}>
          <TotalForPeriod
            equipmentData={rigEquipmentQuery.data}
            loading={rigEquipmentQuery.isFetching}
          />
          <TotRegeneratedEnergyDrawworks
            equipmentData={rigEquipmentQuery.data}
            loading={rigEquipmentQuery.isFetching}
          />
        </Stack>
      </Grid>
      <Grid item xs={8.5}>
        <PeriodicOverviewDetails
          equipmentData={rigEquipmentQuery.data}
          loading={rigEquipmentQuery.isFetching}
        />
      </Grid>
    </Grid>
  );
}

EquipmentSection.propTypes = {};

EquipmentSection.defaultProps = {};

export default EquipmentSection;
