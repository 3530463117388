import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Typography } from '@esgian/esgianui';
import PeriodicOverviewChart from '@components/Charts/PeriodicOverviewChart/PeriodicOverviewChart';
import { useSeriesBuilder, useDisplayUnit } from '@hooks';
import { useSelector } from 'react-redux';
import { getDisplayUnit } from '@store/features';
import { UTC_TIMESTAMP } from '@constants';
import moment from 'moment/moment';
import { getValue } from '@helpers';

function PeriodicOverview({ loading, overviewData }) {
  const displayUnit = useSelector(getDisplayUnit);

  const { getUnitTitle } = useDisplayUnit();
  const { getSeriesData } = useSeriesBuilder();

  const {
    categories = [],
    series = [],
    yAxisTitle = ''
  } = useMemo(() => {
    if (!overviewData) return {};
    const { clarifyData } = overviewData;
    let data = clarifyData[`Rig${displayUnit}`];

    const { max, defaultArray, convertedArray } = getSeriesData(data, displayUnit);
    const { title, converted } = getUnitTitle(max);
    const seriesData = converted ? convertedArray : defaultArray;
    const accumulated = [];
    seriesData.forEach((val, i) => {
      let value = getValue(val);
      if (i === 0) {
        accumulated.push(value);
      } else {
        accumulated.push(accumulated[i - 1] + value);
      }
    });
    return {
      yAxisTitle: title,
      categories: Object.keys(data).map((key) => moment.parseZone(key).format(UTC_TIMESTAMP)),
      series: [
        {
          name: 'Consumption',
          type: 'column',
          data: seriesData
        },
        {
          name: 'Accumulated',
          type: 'line',
          data: accumulated
        }
      ]
    };
  }, [overviewData, displayUnit]);

  return (
    <Paper sx={{ p: 2 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant={'h6'}>Periodic overview</Typography>
        </Grid>
        <Grid item xs={12}>
          <PeriodicOverviewChart
            yAxisTitle={yAxisTitle}
            categories={categories}
            series={series}
            loading={loading}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

PeriodicOverview.propTypes = {
  loading: PropTypes.bool,
  overviewData: PropTypes.object
};

PeriodicOverview.defaultProps = {
  loading: false,
  overviewData: null
};

export default PeriodicOverview;
