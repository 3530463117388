import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, CloseIcon, Modal } from '@esgian/esgianui';
import CreateBaselineType from '@components/Inputs/BaselineInput/BaselineModal/CreateBaselineType';
import { BASELINE_TYPE } from '@constants';
import BaselineDetails from '@components/Inputs/BaselineInput/BaselineModal/BaselineDetails';
import ManageBaselines from '@components/Inputs/BaselineInput/BaselineModal/ManageBaselines';
import { createBaseline, updateBaseline } from '@api/Baselines';
import { useDispatch, useSelector } from 'react-redux';
import { addBaseline, getNextRankNumber, getUser, updateBaselineDetails } from '@store/features';
import { toast } from 'react-toastify';
import { setSelectedBaseline } from '@store/features/filters/BaselineComparisonSlice/BaselineComparisonSlice';
import Swal from 'sweetalert2';
import { useTheme } from '@hooks';

const checkValidBaselineDetails = (baseline) => {
  const {
    rigId,
    startDate,
    endDate,
    baselineName,
    fuelConsumption,
    co2Emissions,
    noxEmissions,
    fuelWaste
  } = baseline;
  if (!rigId || !startDate || !endDate || !baselineName) {
    return false;
  }

  if (baseline.baselineTypeId === BASELINE_TYPE.TARGET) {
    if (!fuelConsumption || !co2Emissions || !noxEmissions || !fuelWaste) {
      return false;
    }
  }
  return true;
};

function BaselineModal({ handleClose, modalType }) {
  const [stage, setStage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [highlightErrors, setHighlightErrors] = useState(false);
  const [baseline, setBaseline] = useState({
    daily: false,
    baselineTypeId: BASELINE_TYPE.TIME_WELL
  });
  let rank = useSelector(getNextRankNumber);
  let user = useSelector(getUser);
  const { theme } = useTheme();
  const dispatch = useDispatch();

  const { title, showButtons, btnText } = useMemo(() => {
    let tempTitle = '';
    let tempShowButtons = true;
    let tempBtnText = 'Save';

    if (stage === 1 && modalType === 'create') {
      tempTitle = 'Select baseline type';
      tempBtnText = 'Next';
    } else if (stage === 1 && modalType === 'manage') {
      tempTitle = 'Manage baselines';
      tempShowButtons = false;
    } else if (stage === 2 && baseline.baselineTypeId === BASELINE_TYPE.TIME_WELL) {
      tempTitle = 'Time/Well baseline ';
    } else if (stage === 2 && baseline.baselineTypeId === BASELINE_TYPE.TARGET) {
      tempTitle = 'Target baseline ';
    }

    return {
      title: tempTitle,
      showButtons: tempShowButtons,
      btnText: tempBtnText
    };
  }, [stage, modalType, baseline.baselineTypeId]);

  const handleSave = async () => {
    if (stage === 1 && modalType === 'create') {
      setStage(2);
      return;
    }
    if (!checkValidBaselineDetails(baseline)) {
      setHighlightErrors(true);
      return;
    }
    setLoading(true);
    Swal.fire({
      title: 'Saving...',
      background: theme.palette.neutral.neutral01,
      color: theme.palette.text.primary
    });
    Swal.showLoading();

    if (stage === 2 && modalType === 'create') {
      let newBaseline = { ...baseline, rank: rank, userId: user.id, companyId: null };
      await createBaseline(newBaseline)
        .then((response) => {
          dispatch(addBaseline(response));
          dispatch(setSelectedBaseline(response));
          toast.success(`Baseline created`);
          handleClose();
        })
        .catch(() => {
          toast.error(`Unable to create baseline`);
        })
        .finally(() => {
          setLoading(false);
          Swal.close();
        });
    }
    if (stage === 2 && modalType === 'manage') {
      await updateBaseline(baseline)
        .then(() => {
          dispatch(updateBaselineDetails(baseline));
          dispatch(setSelectedBaseline(baseline));
          toast.success(`Baseline updated`);
          handleClose();
        })
        .catch(() => {
          toast.error(`Unable to create baseline`);
        })
        .finally(() => {
          Swal.close();
          setLoading(false);
        });
    }
  };
  if (loading) {
    return <></>;
  }
  return (
    <Modal
      sx={{ visibility: isOpen ? 'visible' : 'hidden' }}
      DialogContentProps={{ borderBottom: 'none' }}
      dividers={true}
      DialogActionsProps={{ sx: { justifyContent: 'center' } }}
      actions={
        showButtons
          ? [
              <Button
                sx={{ color: (theme) => theme.palette.secondary.contrastText }}
                key={'cancel-button'}
                variant={'text'}
                onClick={handleClose}>
                Cancel
              </Button>,
              <Button key={'save-button'} onClick={handleSave}>
                {btnText}
              </Button>
            ]
          : null
      }
      closeIcon={<CloseIcon fontSize={'small'} />}
      PaperProps={{
        sx: {
          background: ({ palette }) => palette.neutral.neutral01
        }
      }}
      size={'sm'}
      open
      handleClose={handleClose}
      title={title}>
      <Box sx={{ p: 2, maxHeight: '60vh', minHeight: '250px' }}>
        {stage === 1 && modalType === 'create' && (
          <CreateBaselineType setBaseline={setBaseline} baseline={baseline} />
        )}
        {stage === 1 && modalType === 'manage' && (
          <ManageBaselines
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            setBaseline={setBaseline}
            setStage={setStage}
            baseline={baseline}
          />
        )}
        {stage === 2 && (
          <BaselineDetails
            highlightErrors={highlightErrors}
            setBaseline={setBaseline}
            baseline={baseline}
          />
        )}
      </Box>
    </Modal>
  );
}

BaselineModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  modalType: PropTypes.oneOf(['create', 'manage']).isRequired
};

BaselineModal.defaultProps = {};

export default BaselineModal;
