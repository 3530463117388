import { useCallback, useMemo } from 'react';
import moment from 'moment-timezone';
import { DATE_TIMEZONE_FORMAT } from '@constants';
import { useSelector } from 'react-redux';
import { getMainPageSlice } from '@store/features/filters/MainPageSlice/MainPageSlice';

export const useTimezone = () => {
  const { timeZone, customUTC, rigUTC } = useSelector(getMainPageSlice);

  const selectedTimeZone = useMemo(() => {
    return timeZone === 'CustomTimeZone' ? customUTC?.value : rigUTC;
  }, [timeZone, customUTC, rigUTC]);

  const timezoneOffsetString = useMemo(() => {
    return moment.tz(selectedTimeZone).format('Z');
  }, [selectedTimeZone]);

  const calculateDates = useCallback(
    (start_date, end_date) => {
      if (!start_date || !end_date) return { dateStart: null, dateEnd: null };
      const startZone = moment.tz(start_date, selectedTimeZone).startOf('day');
      const endZone = moment.tz(end_date, selectedTimeZone).endOf('day');
      const start = startZone.format(DATE_TIMEZONE_FORMAT);
      const end = endZone.format(DATE_TIMEZONE_FORMAT);
      return { dateStart: start, dateEnd: end };
    },
    [selectedTimeZone]
  );
  const calculateDatesWithPadding = useCallback(
    (start_date, end_date, start, end = null) => {
      let endValue = end;
      if (!endValue) {
        endValue = start;
      }

      const dateStart = moment
        .tz(start_date, selectedTimeZone)
        .startOf('day')
        .subtract(start.value, start.unit)
        .format(DATE_TIMEZONE_FORMAT);
      const dateEnd = moment
        .tz(end_date, selectedTimeZone)
        .endOf('day')
        .add(endValue.value, endValue.unit)
        .format(DATE_TIMEZONE_FORMAT);
      return { dateStart: dateStart, dateEnd: dateEnd };
    },
    [selectedTimeZone]
  );

  return { calculateDates, selectedTimeZone, calculateDatesWithPadding, timezoneOffsetString };
};
