import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Button,
  CloseIcon,
  IconButton,
  InfoOutlineIcon,
  Modal,
  Stack,
  Typography
} from '@esgian/esgianui';
import SettingInput from '@components/Modals/FocusAreaSettingModal/SettingInput';
import { getRigSettings } from '@api/Settings';
import Swal from 'sweetalert2';
import { useTheme } from '@hooks';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getMainPageRig } from '@store/features/filters/MainPageSlice/MainPageSlice';

const alertText = `A focus area is an event where fuel, cost and emissions could potentially have been reduced. 
The following events are designated as potential focus areas. 
These limits may be changed to adjust when a focus area will be triggered. 
As an example, if too many engines has run for over x minutes and fuel savings would have been possible, it will be highlighted as a focus area. 
GPR Live will suggest an optimal amount of running engines without exceeding the "Max allowable engine peak load" parameter. 
The potential savings will be estimated based on better engine fuel efficiency (SFOC) and maintenance cost saved.`;
const disclaimerBold = `Operational and safety requirements always take precedence`;
const disclaimer = ` over any recommendations provided by Esgian. Do not jeopardize safety or operational standards when implementing the recommendations provided. Esgian cannot be held responsible for any consequences. Esgian assumes operators have the knowledge and training required to always take safe actions.`;

function FocusAreaSettingModal({ saveSettings, handleClose }) {
  const [fields, setFields] = useState({});
  const [loading, setLoading] = useState(true);
  const [isInfoOpen, setIsInfoOpen] = useState(true);
  const [invalidInputs, setInvalidInputs] = useState({
    triggerMaxGenLoad: false,
    triggerExcessGeneratorTime: false,
    settingGenMaintenanceCost: false,
    triggerMaxHpuLoad: false,
    triggerExcessHpuTime: false,
    triggerMpAuxIdleTime: false,
    triggerDwAuxIdleTime: false
  });
  const { theme } = useTheme();
  const selectedRig = useSelector(getMainPageRig);

  const allowSave = useMemo(() => {
    return !Object.values(invalidInputs).some((val) => val === true);
  }, [invalidInputs]);

  const handleSave = () => {
    const controller = new AbortController();
    const { signal } = controller;
    Swal.fire({
      title: 'Saving settings...',
      background: theme.palette.neutral.neutral01,
      color: theme.palette.text.primary
    });

    saveSettings(signal, fields, theme);
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const getSettingsData = async () => {
      setLoading(true);
      try {
        await getRigSettings(signal, selectedRig).then((result) => {
          setFields({
            settingFuelUnit: result.settingFuelUnit,
            settingGenMaintenanceCost: result.settingGenMaintenanceCost,
            triggerMaxGenLoad: result.triggerMaxGenLoad,
            triggerExcessGeneratorTime: result.triggerExcessGeneratorTime,
            triggerMaxHpuLoad: result.triggerMaxHpuLoad,
            triggerExcessHpuTime: result.triggerExcessHpuTime,
            triggerMpAuxIdleTime: result.triggerMpAuxIdleTime,
            triggerTdAuxIdleTime: result.triggerTdAuxIdleTime,
            triggerDwAuxIdleTime: result.triggerDwAuxIdleTime
          });
          setLoading(false);
        });
      } catch (error) {
        if (!signal.aborted) {
          setLoading(false);
          setFields({});
          toast.error(`Unable to fetch settings data`, {
            type: 'error',
            position: 'top-right'
          });
          handleClose();
        }
      }
    };
    if (selectedRig) {
      getSettingsData();
    }
    return () => {
      controller.abort();
    };
  }, []);

  const {
    triggerMaxGenLoad,
    triggerExcessGeneratorTime,
    triggerExcessHpuTime,
    triggerMpAuxIdleTime,
    triggerDwAuxIdleTime,
    triggerMaxHpuLoad,
    settingGenMaintenanceCost
  } = fields;

  const handleFieldChange = (val, invalid) => {
    let obj = {};
    obj[Object.keys(val)[0]] = invalid;
    setInvalidInputs((prevField) => {
      return { ...prevField, ...obj };
    });
    setFields((prevField) => {
      return { ...prevField, ...val };
    });
  };

  return (
    <Modal
      closeIcon={<CloseIcon fontSize={'small'} />}
      PaperProps={{
        sx: {
          background: ({ palette }) => palette.neutral.neutral01
        }
      }}
      size={'sm'}
      open
      handleClose={handleClose}
      title={'Focus area settings'}
      DialogActionsProps={{ sx: { justifyContent: 'center' } }}
      actions={[
        <Button onClick={handleClose} variant={'text'} key={'cancel-button'}>
          Cancel
        </Button>,
        <Button
          key={'save-button'}
          disabled={loading || !allowSave}
          onClick={handleSave}
          variant={'contained'}>
          Save
        </Button>
      ]}>
      <IconButton
        size={'small'}
        color={'primary'}
        sx={{ position: 'absolute', top: 20, left: 198 }}
        onClick={() => setIsInfoOpen(true)}>
        <InfoOutlineIcon fontSize={'16px'} />
      </IconButton>
      <Stack spacing={2}>
        {isInfoOpen ? (
          <Alert
            icon={
              <InfoOutlineIcon
                fontSize={'small'}
                sx={{ color: ({ palette }) => palette.neutral.primary }}
              />
            }
            sx={{
              color: ({ palette }) => palette.neutral.primary,
              backgroundColor: ({ palette }) => palette.neutral.neutral04
            }}
            severity={'info'}
            variant={'filled'}>
            <Stack direction="row">
              <Stack spacing={3}>
                <Typography variant={'body2'}>{alertText}</Typography>
                <Typography variant={'body2'}>
                  <b>{disclaimerBold}</b>
                  {disclaimer}
                </Typography>
              </Stack>
              <Stack>
                <IconButton size={'small'} sx={{ p: 0 }} onClick={() => setIsInfoOpen(false)}>
                  <CloseIcon
                    fontSize={'16px'}
                    sx={{ p: 0, color: ({ palette }) => palette.secondary.dark }}
                  />
                </IconButton>
              </Stack>
            </Stack>
          </Alert>
        ) : null}
        <Stack spacing={5}>
          <Stack>
            <Typography variant={'subtitle1'} bold>
              Too many engines running
            </Typography>
            <Stack spacing={1}>
              <SettingInput
                min={60}
                max={100}
                onChange={(val, invalid) => handleFieldChange({ triggerMaxGenLoad: val }, invalid)}
                value={triggerMaxGenLoad}
                loading={loading}
                title={'Max allowable engine peak load (%)'}
              />
              <SettingInput
                min={30}
                onChange={(val, invalid) =>
                  handleFieldChange({ triggerExcessGeneratorTime: val }, invalid)
                }
                value={triggerExcessGeneratorTime}
                loading={loading}
                title={'Min duration to trigger a focus area (min)'}
              />
              <SettingInput
                min={0}
                max={1000}
                onChange={(val, invalid) =>
                  handleFieldChange({ settingGenMaintenanceCost: val }, invalid)
                }
                value={settingGenMaintenanceCost}
                loading={loading}
                title={'Generator maintenance cost avg pr hour (USD)'}
              />
            </Stack>
          </Stack>
          <Stack spacing={2}>
            <Typography variant={'subtitle1'} bold>
              Too many HPU pumps online
            </Typography>
            <SettingInput
              min={60}
              max={100}
              onChange={(val, invalid) => handleFieldChange({ triggerMaxHpuLoad: val }, invalid)}
              value={triggerMaxHpuLoad}
              loading={loading}
              title={'Max allowable pump peak load (%)'}
            />
            <SettingInput
              min={20}
              onChange={(val, invalid) => handleFieldChange({ triggerExcessHpuTime: val }, invalid)}
              value={triggerExcessHpuTime}
              loading={loading}
              title={'Min duration to trigger a focus area (min)'}
            />
          </Stack>
          <Stack spacing={2}>
            <Typography variant={'subtitle1'} bold>
              Idle Mud pumps
            </Typography>
            <SettingInput
              min={20}
              onChange={(val, invalid) => handleFieldChange({ triggerMpAuxIdleTime: val }, invalid)}
              value={triggerMpAuxIdleTime}
              loading={loading}
              title={'Min allowable duration to trigger a focus area (min)'}
            />
          </Stack>
          <Stack spacing={2}>
            <Typography variant={'subtitle1'} bold>
              Idle Drawworks
            </Typography>
            <SettingInput
              min={20}
              onChange={(val, invalid) => handleFieldChange({ triggerDwAuxIdleTime: val }, invalid)}
              value={triggerDwAuxIdleTime}
              loading={loading}
              title={'Min allowable duration to trigger a focus area (min)'}
            />
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  );
}

FocusAreaSettingModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  saveSettings: PropTypes.func.isRequired
};

FocusAreaSettingModal.defaultProps = {};

export default FocusAreaSettingModal;
