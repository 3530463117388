import React, { useMemo, useState } from 'react';
import { Grid, Paper, Stack } from '@esgian/esgianui';
import { DrillingActivitySummary, LegendValue } from '@components';
import moment from 'moment-timezone';
import { PeriodicOverviewWithDetailsChart } from '@components/Charts';
import { useDisplayUnit, useSeriesBuilder } from '@hooks';
import { TIMESTAMP, UTC_TIMESTAMP } from '@constants';
import LogDrawer from '@components/Drawers/LogDrawer/LogDrawer';
import { useSelector } from 'react-redux';
import { getMainPageSlice } from '@store/features/filters/MainPageSlice/MainPageSlice';
import { useTimezone } from '@hooks/useTimezone';
import { useMainPageQueries } from '@hooks/usePageQueries/useMainPageQueries';

const legendsColorMapping = {
  Cementing: '#E7C014',
  'Condition mud & circulating': '#20AFFF',
  'Pressure Test Hole': '#E69700',
  'Run casing': '#4ED5FF',
  Tripping: '#017CFF',
  'Nipple B.O.P.': '#E68600',
  Safety: '#555555',
  'Service Rig': '#9D9D9D',
  'Well Control': '#E4B600',
  Drilling: '#1557FF',
  Completion: '#4EFFFF',
  'Test Well Control Equipment': '#EACC45',
  'Wireline, Coiled Tubing & Logging': '#E5A900',
  Reaming: '#BAB1F0',
  Coring: '#A7C0FF',
  'Mechanical Plug & Whipstock': '#C4D4FF',
  'Non Productive Time': '#434343',
  'Rig Move Jack Up': '#7B7B7B',
  'Rig Downtime': '#C4C4C4',
  Abandonment: '#D9D9D9',
  'Rig off contract': '#C7DEC8',
  Other: '#8ECE90',
  'Activity data missing': '#FF495C',
  Standby: '#C7DEC8'
};

const orderMap = {
  drilling: 0,
  tripping: 1,
  'condition mud & circulating': 2,
  'run casing': 3,
  completion: 4,
  reaming: 5,
  coring: 6,
  'mechanical plug & whipstock': 7,
  'nipple b.o.p.': 8,
  'pressure test hole': 9,
  'wireline, coiled tubing & logging': 10,
  'well control': 11,
  cementing: 12,
  'test well control equipment': 13,
  'non productive time': 14,
  safety: 15,
  'rig move jack up': 16,
  'service rig': 17,
  'rig downtime': 18,
  abandonment: 19,
  standby: 20,
  'rig off contract': 21,
  other: 22,
  'activity data missing': 23
};

const sumDataByUnit = (data, viewByUnit, displayUnit, selectedTimeZone, start, end) => {
  let activities = [];
  let cat = [];
  let dataTimes = {};
  let startDate = moment(start);
  let endDate = moment(end);
  while (startDate.isSameOrBefore(endDate)) {
    let dayKey = startDate.clone().format(UTC_TIMESTAMP);
    cat.push(dayKey);
    dataTimes[dayKey] = 0;
    startDate.add(1, viewByUnit);
  }

  data.forEach(({ activity, drillingActivityEvents }) => {
    let data = { ...dataTimes };

    Object.entries(drillingActivityEvents[`drilling${displayUnit}`]).forEach(([key, value]) => {
      let dayKey = moment.parseZone(key).startOf(viewByUnit).format(UTC_TIMESTAMP);
      if (data[dayKey] !== undefined) {
        data[dayKey] += parseFloat(value);
      }
    });

    activities.push({ name: activity, data: data });
  });

  return { data: activities, categories: cat };
};

function DrillingActivitiesSection() {
  const [logInfo, setLogInfo] = useState(null);
  const [loadingDetails, setLoadingDetails] = useState(true);
  const [selectedColumn, setSelectedColumn] = useState(0);
  const { getUnitTitle, displayUnit } = useDisplayUnit();
  const { getSeriesData } = useSeriesBuilder();
  const { startDate, endDate } = useSelector(getMainPageSlice);
  const { rigDrillingActivityQuery } = useMainPageQueries();
  const { selectedTimeZone } = useTimezone();

  const getSeriesAndUnit = (data) => {
    if (!data?.length) return {};
    let max = 0;
    let series = data?.map(({ name, data }) => {
      const { max: seriesMax, defaultArray, convertedArray } = getSeriesData(data, displayUnit);
      max = seriesMax > max ? seriesMax : max;
      return {
        name: name,
        data: { defaultArray: defaultArray, convertedArray: convertedArray }
      };
    });
    const { converted, title } = getUnitTitle(max);

    series = series
      .map(({ name, data }) => {
        let tempName = name;
        if (tempName === 'Uncategorized') {
          tempName = 'Activity data missing';
        }
        return { name: tempName, data: converted ? data.convertedArray : data.defaultArray };
      })
      .sort((a, b) => {
        const indexA = orderMap[a.name.toLowerCase()];
        const indexB = orderMap[b.name.toLowerCase()];
        return indexA - indexB;
      });
    return { series, unit: title };
  };

  const {
    primarySeries = [],
    primUnit = '',
    primaryCategories = []
  } = useMemo(() => {
    if (!rigDrillingActivityQuery.data) return {};
    const { data, categories } = sumDataByUnit(
      rigDrillingActivityQuery.data,
      'day',
      displayUnit,
      selectedTimeZone,
      startDate,
      endDate
    );
    const { series, unit } = getSeriesAndUnit(data);
    return { primarySeries: series, primUnit: unit, primaryCategories: categories };
  }, [rigDrillingActivityQuery.data, displayUnit]);

  const {
    secondarySeries = [],
    secondaryUnit = '',
    secondaryCategories = []
  } = useMemo(() => {
    if (!primaryCategories.length) return {};
    let sDate = moment(primaryCategories[selectedColumn]).startOf('day');
    let eDate = moment
      .tz(primaryCategories[selectedColumn].slice(0, -1), selectedTimeZone)
      .endOf('day');

    if (selectedColumn >= 1) {
      sDate = sDate.subtract(1, 'days');
    }
    if (selectedColumn < primaryCategories?.length - 1) {
      eDate = eDate.add(1, 'days');
    }
    if (eDate.isAfter(moment.tz(selectedTimeZone))) {
      eDate = moment.tz(selectedTimeZone);
    }
    eDate = moment(eDate.format(TIMESTAMP));
    const { data, categories } = sumDataByUnit(
      rigDrillingActivityQuery.data,
      'hour',
      displayUnit,
      selectedTimeZone,
      sDate,
      eDate
    );
    const { series: series, unit } = getSeriesAndUnit(data);
    setLoadingDetails(false);
    return { secondarySeries: series, secondaryUnit: unit, secondaryCategories: categories };
  }, [primaryCategories, selectedColumn]);

  const coloredPrimarySeries = useMemo(() => {
    if (!primarySeries.length) return [];
    return primarySeries?.map((item) => {
      return legendsColorMapping?.[item?.name || 'Other'];
    });
  }, [primarySeries]);

  const secondaryColorPalette = useMemo(() => {
    if (!secondarySeries.length) return [];
    return secondarySeries?.map((item) => {
      return legendsColorMapping?.[item?.name || 'Other'];
    });
  }, [secondarySeries]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={3.5}>
        <DrillingActivitySummary
          drillingData={rigDrillingActivityQuery.data}
          loading={rigDrillingActivityQuery.isFetching}
        />
      </Grid>
      <Grid item xs={8.5}>
        <Paper sx={{ p: 2 }}>
          <PeriodicOverviewWithDetailsChart
            colorPalette={coloredPrimarySeries}
            secondaryColorPalette={secondaryColorPalette}
            secondaryChartStacked
            unit={primUnit}
            secondaryUnit={secondaryUnit}
            loadingSecondarySeries={loadingDetails}
            loading={rigDrillingActivityQuery.isFetching}
            selectedView={'hour'}
            secondaryChartType={'bar'}
            selectedColumn={selectedColumn}
            setSelectedColumn={setSelectedColumn}
            secondarySeries={secondarySeries}
            handleLogClick={setLogInfo}
            primaryCategories={primaryCategories}
            primarySeries={primarySeries}
            secondaryCategories={secondaryCategories}
            legend={
              <Stack direction={'row'} flexWrap={'wrap'}>
                {primarySeries?.map(({ name }, i) => (
                  <LegendValue
                    sx={{ pr: 2 }}
                    key={`legend-${i}-po`}
                    value={name}
                    color={{ name: 'eighteenColorsStacked', index: i }}
                  />
                ))}
              </Stack>
            }
          />
          <LogDrawer handleClose={() => setLogInfo(null)} open={!!logInfo} logInfo={logInfo} />
        </Paper>
      </Grid>
    </Grid>
  );
}

DrillingActivitiesSection.propTypes = {};

DrillingActivitiesSection.defaultProps = {};

export default DrillingActivitiesSection;
