import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  CalendarNav,
  CalendarNext,
  CalendarPrev,
  DateRangeMobi,
  Stack
} from '@esgian/esgianui';
import { DATE_FORMAT } from '@constants';
import moment from 'moment-timezone';

function CalendarDisplay({
  onSave,
  setAnchorEl,
  startDate,
  endDate,
  open,
  includeWellDetails,
  maxDate,
  minStartDate
}) {
  const [tempDates, setTempDates] = useState([null, null]);

  const resetTempDates = useCallback(() => {
    let dates = [];
    dates[0] = startDate ? moment(startDate, DATE_FORMAT) : null;
    dates[1] = endDate ? moment(endDate, DATE_FORMAT) : null;
    setTempDates(dates);
  }, [startDate, endDate]);

  useEffect(() => {
    resetTempDates();
  }, [startDate, endDate, open]);

  return (
    <Stack spacing={2} sx={{ p: 2 }}>
      <Box sx={{ width: '318px', alignSelf: 'center' }}>
        <DateRangeMobi
          maxDate={maxDate}
          minStartDate={minStartDate}
          dateFormat={DATE_FORMAT}
          onClose={() => {}}
          sx={{
            inputProps: {
              autocomplete: 'off',
              defaultValue: undefined,
              placeholder: 'Period',

              value:
                startDate || endDate
                  ? `${startDate ? moment(startDate).format('MMM YYYY') : ''} - ${
                      endDate ? moment(endDate).format('MMM YYYY') : ''
                    }`
                  : null
            },
            renderCalendarHeader: () => (
              <Box sx={{ width: '100%' }}>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <CalendarPrev />
                  <CalendarNav />
                  <CalendarNext />
                </Stack>
              </Box>
            ),
            touchUi: true,
            display: 'inline',
            theme: 'windows',
            value: tempDates,
            onChange: ({ value }) => {
              setTempDates(value);
            }
          }}
        />
      </Box>
      <Stack direction={'row'} alignSelf={'center'} spacing={3}>
        <Button
          onClick={() => {
            setAnchorEl(null);
            resetTempDates();
          }}
          color={'secondary'}
          sx={{ color: (theme) => theme.palette.secondary.contrastText }}
          variant={'text'}>
          Cancel
        </Button>
        <Button
          variant={'contained'}
          onClick={() => {
            let res = {
              startDate: tempDates[0].format(DATE_FORMAT),
              endDate: tempDates[1].format(DATE_FORMAT)
            };

            if (includeWellDetails) {
              res.well = null;
            }
            onSave(res);
            setAnchorEl(null);
          }}>
          Apply
        </Button>
      </Stack>
    </Stack>
  );
}

CalendarDisplay.propTypes = {
  minStartDate: PropTypes.string,
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  open: PropTypes.bool,
  includeWellDetails: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  setAnchorEl: PropTypes.func.isRequired
};

CalendarDisplay.defaultProps = {
  includeWellDetails: false,
  open: false,
  startDate: null,
  minStartDate: undefined,
  maxDate: moment(),
  endDate: null,
  tempDates: [null, null]
};

export default CalendarDisplay;
