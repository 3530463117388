import React from 'react';
import { Grid } from '@esgian/esgianui';
import {
  DrillingEquipment,
  EnergyMonitor,
  Operations,
  OperationsPeriodicOverview,
  PeriodicOverview,
  PowerManagement,
  RigKeyFigures
} from '@components';
import { useMainPageQueries } from '@hooks/usePageQueries/useMainPageQueries';

function OverviewSection() {
  const { rigOverviewQuery, rigKeyFiguresQuery, rigAnalyticsQuery } = useMainPageQueries();

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <RigKeyFigures
          loading={rigKeyFiguresQuery.isFetching}
          overviewKeyFiguresData={rigKeyFiguresQuery.data}
        />
      </Grid>
      <Grid item xs={6}>
        <EnergyMonitor
          isOverview
          loading={rigAnalyticsQuery.isFetching}
          analyticsData={rigAnalyticsQuery.data}
        />
      </Grid>
      <Grid item xs={12}>
        <PeriodicOverview
          loading={rigOverviewQuery.isFetching}
          overviewData={rigOverviewQuery.data}
        />
      </Grid>
      <Grid item xs={4}>
        <Operations loading={rigOverviewQuery.isFetching} overviewData={rigOverviewQuery.data} />
      </Grid>
      <Grid item xs={8}>
        <OperationsPeriodicOverview
          loading={rigOverviewQuery.isFetching}
          overviewData={rigOverviewQuery.data}
        />
      </Grid>
      <Grid item xs={6}>
        <DrillingEquipment
          loading={rigOverviewQuery.isFetching}
          overviewData={rigOverviewQuery.data}
        />
      </Grid>
      <Grid item xs={6}>
        <PowerManagement
          loading={rigOverviewQuery.isFetching}
          overviewData={rigOverviewQuery.data}
        />
      </Grid>
    </Grid>
  );
}

OverviewSection.propTypes = {};

OverviewSection.defaultProps = {};

export default OverviewSection;
