import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMyBaselines, removeBaseline, setBaselineArray } from '@store/features';
import {
  Button,
  CircularProgress,
  DeleteOutlineIcon,
  IconButton,
  MenuIcon,
  Modal,
  Stack,
  Typography
} from '@esgian/esgianui';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import { deleteBaseline, updateBaseline } from '@api/Baselines';
import { toast } from 'react-toastify';
import {
  getSelectedBaseline,
  setSelectedBaseline
} from '@store/features/filters/BaselineComparisonSlice/BaselineComparisonSlice';

function ManageBaselines({ setBaseline, setStage, setIsOpen, isOpen }) {
  const [loading, setLoading] = useState(false);
  const [selectedDeleteBaseline, setSelectedDeleteBaseline] = useState(null);
  const lookupBaselines = useSelector(getMyBaselines);
  const selectedBaseline = useSelector(getSelectedBaseline);
  const [tempBaselines, setTempBaselines] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setTempBaselines([...lookupBaselines]);
  }, [lookupBaselines]);

  const handleSave = useCallback(async () => {
    let apis = [];
    for (let i = 0; i < tempBaselines.length; i++) {
      apis.push(updateBaseline(tempBaselines[i]));
    }

    setLoading(true);
    await Promise.all(apis).finally(() => setLoading(false));

    dispatch(setBaselineArray(tempBaselines));
    toast.success(`Baselines updated`);
  }, [tempBaselines, lookupBaselines]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = ({ destination, source }) => {
    if (!destination) return;

    const newItems = reorder(tempBaselines, source.index, destination.index);

    setTempBaselines(
      newItems?.map((baseline, i) => {
        return { ...baseline, rank: i + 1 };
      })
    );
  };
  const handleDeleteClick = async () => {
    await deleteBaseline({ baselineId: selectedDeleteBaseline.baselineId })
      .then(() => {
        toast.success(`Baselines deleted`);
        dispatch(removeBaseline({ baselineId: selectedDeleteBaseline.baselineId }));
        if (selectedBaseline.baselineId === selectedDeleteBaseline.baselineId) {
          dispatch(setSelectedBaseline(null));
        }
      })
      .catch(() => {
        toast.error(`Unable to delete baselines`);
      })
      .finally(() => {
        setSelectedDeleteBaseline(null);
        setIsOpen(true);
      });
    return null;
  };

  const hasOrderChange = useMemo(() => {
    return tempBaselines.some(
      (baseline, i) => JSON.stringify(baseline) !== JSON.stringify(lookupBaselines[i])
    );
  }, [tempBaselines, lookupBaselines]);

  if (!isOpen) {
    return (
      <Modal open handleClose={() => setIsOpen(true)}>
        <Stack>
          <Typography sx={{ p: 5, alignSelf: 'center' }} variant={'h6'}>
            Do you want to delete this baseline?
          </Typography>
          <Stack direction={'row'} spacing={3} justifyContent={'center'}>
            <Button
              onClick={() => {
                setSelectedDeleteBaseline(null);
                setIsOpen(true);
              }}
              sx={{ color: (theme) => theme.palette.secondary.contrastText }}
              variant={'text'}>
              No
            </Button>
            <Button onClick={handleDeleteClick} variant={'contained'} color={'error'}>
              Yes, delete it
            </Button>
          </Stack>
        </Stack>
      </Modal>
    );
  }

  return (
    <Stack>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-list">
          {(provided) => (
            <div
              style={{
                flexDirection: 'column',
                display: 'flex'
              }}
              {...provided.droppableProps}
              ref={provided.innerRef}>
              {tempBaselines.map((baseline, index) => {
                const { baselineName, baselineId } = baseline;
                return (
                  <Draggable
                    key={`item-${baselineId}`}
                    draggableId={`drag-${baselineId}`}
                    index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}>
                        <span style={{ display: 'none' }}>{provided.placeholder}</span>

                        <Stack
                          sx={{
                            '&:hover': {
                              cursor: 'pointer',
                              color: ({ palette }) => palette.primary.dark,
                              backgroundColor: ({ palette }) => palette.neutral.neutral02
                            },
                            pt: 0.5,
                            pb: 0.5
                          }}
                          direction={'row'}
                          justifyContent={'space-between'}>
                          <Stack direction={'row'} spacing={1} alignItems={'center'}>
                            {loading ? (
                              <CircularProgress size={10} />
                            ) : (
                              <MenuIcon fontSize={'16px'} />
                            )}
                            <Typography variant={'body2'}>{baselineName}</Typography>
                          </Stack>
                          <Stack direction={'row'} spacing={1} alignItems={'center'}>
                            <Button
                              disabled={hasOrderChange}
                              onClick={() => {
                                setStage(2);
                                setBaseline(baseline);
                              }}
                              variant={'text'}
                              size={'small'}>
                              Edit
                            </Button>
                            <IconButton
                              onClick={() => {
                                setSelectedDeleteBaseline(baseline);
                                setIsOpen(false);
                              }}
                              disabled={hasOrderChange}
                              size={'small'}
                              color={'primary'}>
                              <DeleteOutlineIcon fontSize={'16px'} color={'inherit'} />
                            </IconButton>
                          </Stack>
                        </Stack>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {hasOrderChange && (
        <Stack direction={'row'} spacing={2} sx={{ pt: 2 }} alignSelf={'center'}>
          <Button
            sx={{ color: (theme) => theme.palette.secondary.contrastText }}
            key={'cancel-button'}
            variant={'text'}
            onClick={() => setTempBaselines([...lookupBaselines])}>
            Cancel
          </Button>
          <Button key={'save-button'} onClick={handleSave}>
            Save
          </Button>
        </Stack>
      )}
    </Stack>
  );
}

ManageBaselines.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func.isRequired,
  setBaseline: PropTypes.func.isRequired,
  setStage: PropTypes.func.isRequired
};

ManageBaselines.defaultProps = {};

export default ManageBaselines;
