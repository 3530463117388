import React, { useCallback, useMemo } from 'react';
import { Stack, Typography } from '@esgian/esgianui';
import BaselineKeyFigures from '@components/Sections/BaselineComparisonSection/SubSections/BaselineKeyFigures';
import PeriodicOverviewComparison from '@components/Sections/BaselineComparisonSection/SubSections/PeriodicOverview/PeriodicOverviewComparison';
import { useSelector } from 'react-redux';
import { getBaselineComparisonSlice } from '@store/features/filters/BaselineComparisonSlice/BaselineComparisonSlice';
import moment from 'moment-timezone';
import DrillingActivitiesComparison from '@components/Sections/BaselineComparisonSection/SubSections/DrillingActivities';
import EquipmentComparison from '@components/Sections/BaselineComparisonSection/SubSections/EquipmentComparison';
import { getMainPageSlice } from '@store/features/filters/MainPageSlice/MainPageSlice';
import AnalyticsComparison from '@components/Sections/BaselineComparisonSection/SubSections/AnalyticsComparison';
import PowerMgmtComparison from '@components/Sections/BaselineComparisonSection/SubSections/PowerMgmtComparison';
import { BASELINE_SUB_SECTIONS } from '@constants';

function BaselineComparisonSection() {
  const { graphDisplays, selectedBaseline } = useSelector(getBaselineComparisonSlice);
  const { selectedRig, startDate, endDate } = useSelector(getMainPageSlice);

  const validFilters = useMemo(() => {
    return !(
      !selectedRig ||
      !selectedBaseline ||
      !startDate ||
      !endDate ||
      !moment(startDate).isValid() ||
      !moment(endDate).isValid()
    );
  }, [selectedRig, selectedBaseline, startDate, endDate]);

  const getKey = useCallback((graphName) => {
    return `section-${graphName.replace(/\s+/g, '-')}`;
  }, []);

  if (!validFilters) {
    return <Typography>Please update filters</Typography>;
  }

  return (
    <Stack spacing={2}>
      <BaselineKeyFigures />
      {graphDisplays
        ?.filter(({ visible }) => visible)
        ?.map(({ graphName }) => {
          switch (graphName) {
            case BASELINE_SUB_SECTIONS.PERIODIC_OVERVIEW:
              return <PeriodicOverviewComparison key={getKey(graphName)} />;
            case BASELINE_SUB_SECTIONS.DRILLING_ACTIVITIES:
              return <DrillingActivitiesComparison key={getKey(graphName)} />;
            case BASELINE_SUB_SECTIONS.EQUIPMENT:
              return <EquipmentComparison key={getKey(graphName)} />;
            case BASELINE_SUB_SECTIONS.POWER_MGMT:
              return <PowerMgmtComparison key={getKey(graphName)} />;
            case BASELINE_SUB_SECTIONS.ANALYTICS:
              return <AnalyticsComparison key={getKey(graphName)} />;
            default:
              break;
          }
        })}
    </Stack>
  );
}

BaselineComparisonSection.propTypes = {};

BaselineComparisonSection.defaultProps = {};

export default BaselineComparisonSection;
