import React from 'react';
import PropTypes from 'prop-types';
import { Box, Skeleton, Stack, Typography } from '@esgian/esgianui';
import { DISPLAY_UNIT, WARNINGS, WARNINGS_TYPE } from '@constants';
import { useTheme, useDisplayUnit } from '@hooks';

function FocusAreaSummary({ warningSummary, loading }) {
  const {
    theme: { palette: { text: { primary } } = {} }
  } = useTheme();
  const { valueWithUnit } = useDisplayUnit();

  return (
    <Stack spacing={1}>
      <Typography bold>Focus area summary</Typography>
      <Stack>
        {Object.keys(WARNINGS_TYPE).map((key, i) => (
          <Stack justifyContent={'space-between'} key={`${i}-focus-area`} direction={'row'}>
            <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{ ml: 1.5 }}>
              <Box
                sx={{
                  marginBottom: '2px',
                  height: '5px',
                  width: '5px',
                  borderRadius: '50%',
                  backgroundColor: primary
                }}
              />
              <Typography variant={'body2'}>
                {loading ? <Skeleton width={'10em'} /> : `${WARNINGS[key]}`}
              </Typography>
            </Stack>
            <Typography variant={'body2'}>
              {loading ? (
                <Skeleton width={'10em'} />
              ) : (
                `${valueWithUnit(warningSummary[key], DISPLAY_UNIT.FUEL)}`
              )}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}

FocusAreaSummary.propTypes = {
  warningSummary: PropTypes.object,
  loading: PropTypes.bool
};

FocusAreaSummary.defaultProps = {
  warningSummary: {},
  loading: false
};

export default FocusAreaSummary;
