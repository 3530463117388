import React, { useMemo } from 'react';
import { Box, Skeleton, Stack, Typography } from '@esgian/esgianui';
import { TopThreeDrillingActivitiesChart } from '@components/Charts';
import { LegendValue } from '@components';
import PropTypes from 'prop-types';
import CategoryKeyValueDisplay from '@components/Charts/TopThreeDrillingActivitiesChart/CategoryKeyValueDisplay';

function TopThreeDrillingActivities({ loading, totalSeries, totalCategories }) {
  const getPercentage = (val, tot) => {
    return (val / tot) * 100;
  };

  const categories = useMemo(() => {
    if (!totalCategories?.length) return [];

    let tempCategory = [];
    for (let i = 0; i < 3; i++) {
      if (i >= totalCategories.length) break;
      tempCategory.push(totalCategories[i]);
    }
    if (totalCategories?.length > 3) {
      tempCategory.push('Others');
    }
    return tempCategory;
  }, [totalCategories]);

  const series = useMemo(() => {
    if (!totalSeries?.length) return [];

    let data = totalSeries[0]?.data;
    let sum = data.reduce((a, b) => a + Math.round(b * 100), 0);

    let tempData = [];
    let otherSum = 0;

    for (let i = 0; i < Math.min(3, data.length); i++) {
      tempData.push(getPercentage(Math.round(data[i] * 100), sum));
    }

    for (let i = 3; i < data.length; i++) {
      otherSum += Math.round(data[i] * 100);
    }

    if (data.length > 3) {
      tempData.push(getPercentage(otherSum, sum));
    }

    // Ensuring the sum of percentages is 100
    let diff = 100 - tempData.reduce((acc, val) => acc + Math.round(val), 0);

    for (let i = tempData.length - 1; diff !== 0; i = (i - 1 + tempData.length) % tempData.length) {
      diff > 0 ? tempData[i]++ && diff-- : tempData[i]-- && diff++;
    }

    // Rounding the values
    tempData = tempData.map(Math.round);

    return tempData;
  }, [totalSeries]);

  return (
    <Stack spacing={2}>
      <Typography variant={'h6'}>
        {loading ? <Skeleton sx={{ width: '20%' }} /> : 'Top 3'}
      </Typography>
      <Stack spacing={1} direction={'row'}>
        {!loading && (
          <>
            <LegendValue color={{ name: 'fourColorsPie', index: 0 }} value={`Top 1`} />
            <LegendValue color={{ name: 'fourColorsPie', index: 1 }} value={`Top 2`} />
            <LegendValue color={{ name: 'fourColorsPie', index: 2 }} value={`Top 3`} />
            <LegendValue color={{ name: 'fourColorsPie', index: 3 }} value={`Others`} />
          </>
        )}
      </Stack>
      <Stack direction={'row'}>
        <Box sx={{ width: '50%' }}>
          <TopThreeDrillingActivitiesChart
            series={series}
            loading={loading}
            categories={categories}
          />
        </Box>
        <Box sx={{ width: '50%', alignSelf: 'center' }}>
          <Stack sx={{ flexWrap: 'wrap' }} direction={'row'} justifyContent={'space-between'}>
            {categories?.map((val, i) => (
              <CategoryKeyValueDisplay
                key={`top-three-${i}`}
                value={series[i]}
                loading={loading}
                category={categories[i]}
                width="48%"
              />
            ))}
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
}

TopThreeDrillingActivities.propTypes = {
  loading: PropTypes.bool,
  totalSeries: PropTypes.arrayOf(PropTypes.object),
  totalCategories: PropTypes.arrayOf(PropTypes.string)
};

TopThreeDrillingActivities.defaultProps = {
  loading: false,
  totalSeries: [],
  totalCategories: []
};

export default TopThreeDrillingActivities;
