import React from 'react';
import { ChartColumnIcon } from '@esgian/esgianui';
import { setSideMenuItemOpen } from '@store/features';

export const getSideMenuList = (routeChange, sideMenuOpen, dropDownMenuOpen, dispatch) => {
  const handleSideMenuClick = (val) => {
    dispatch(setSideMenuItemOpen({ ...dropDownMenuOpen, ...val }));
  };

  return [
    {
      key: 'greenpact-live',
      id: 'greenpact-live-menu',
      icon: <ChartColumnIcon color={'inherit'} />,
      text: 'Greenpact Live',
      onClick: () => {
        handleSideMenuClick({ gprOpen: !dropDownMenuOpen?.gprOpen });
      },
      subSectionOpen: dropDownMenuOpen?.gprOpen,
      subSections: [
        {
          id: 'greenpact-live-sub',
          key: 'greenpact-live-sub',
          text: 'Greenpact Live',
          path: '/greenpact-live',
          onClick: () => {
            routeChange('/greenpact-live');
          }
        }
      ]
    }
  ];
};
