export const checkUserHasAccess = (user) => {
  if (!user) return false;
  const {
    profile: { permissionList }
  } = user;

  return permissionList.includes('GPRLive');
};

export const formatNumberReadable = (number) => {
  if (typeof number === 'number') {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  } else if (typeof number === 'string') {
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
  return '0';
};

export const getValue = (value, zeroFill = true) => {
  if (value === null || value === 'null') {
    if (zeroFill) {
      return 0;
    } else {
      return null;
    }
  }
  return parseFloat(value);
};
/**
 * get sum of a timeseries object
 */
export const getTotSum = (val) => {
  return Object.values(val).reduce((a, b) => a + getValue(b), 0);
};

/**
 * get percent difference between two numbers
 */
export const getPercentDiff = (main, sec) => {
  if (sec === 0) {
    return 'N/A';
  }

  if (main === 0) {
    return -100;
  }

  let res = (main / sec - 1) * 100;
  let absRes = Math.abs(res);
  if (absRes > 100) {
    return parseInt(res);
  } else if (absRes < 100 && absRes >= 10) {
    return res.toFixed(1);
  }
  return res.toFixed(2);
};
