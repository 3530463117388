import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  TimeScale,
  LineController,
  BarElement,
  BarController
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import 'chartjs-adapter-moment';
import { Chart } from 'react-chartjs-2';
import { useTheme } from '@hooks';
import PropTypes from 'prop-types';
import { ApexChart, FormControlLabel, Stack, Switch, Typography } from '@esgian/esgianui';
import { LegendValue } from '@components';
import moment from 'moment';
import { BASELINE_CHART_TYPE, DATA_TYPE, DATE_FORMAT, DATE_TIME_FORMAT } from '@constants';
import { parseNumberToSignificant } from '@helpers';
import { useSelector } from 'react-redux';
import { getMainDurationHours } from '@store/features/filters/MainPageSlice/MainPageSlice';
import { getBaselineDurationHours } from '@store/features/filters/BaselineComparisonSlice/BaselineComparisonSlice';
import { getOrCreateTooltipDiv } from '@components/Charts/ChartJsTooltipHelpers';
import { hoverLinePlugin, yAxisTitle } from '@components/ChartJsPlugins';

const externalTooltipHandler = (
  context,
  theme,
  yAxisTitle,
  chartType,
  mainData = [],
  secData = [],
  secondary = null
) => {
  // Tooltip Element
  if (!context) return;
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltipDiv(chart, theme);
  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  const {
    palette: {
      tooltip: { divider }
    }
  } = theme;
  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const dataPoints = mainData.datasets;
    const dataIndex = tooltip.dataPoints[0].dataIndex;

    let diff = (dataPoints[1]?.data[dataIndex] ?? 0) - (dataPoints[0]?.data[dataIndex] ?? 0);
    if (chartType === BASELINE_CHART_TYPE.BY_TIME) {
      diff = (dataPoints[1]?.data[dataIndex].y ?? 0) - (dataPoints[0]?.data[dataIndex].y ?? 0);
    }
    const header = document.createElement('div');
    header.style.fontWeight = 'bold';
    header.style.fontSize = '16px';
    header.textContent = titleLines[0];

    const tableBody = document.createElement('div');
    const diffContent = document.createElement('div');
    diffContent.style.display = 'flex';
    diffContent.style.gap = '24px';
    diffContent.style.justifyContent = 'space-between';

    const diffLegendWrapper = document.createElement('div');
    diffLegendWrapper.style.display = 'flex';
    diffLegendWrapper.style.alignItems = 'center';
    diffLegendWrapper.style.gap = '8px';

    const diffTitleText = document.createElement('span');
    diffTitleText.style.fontSize = '14px';
    diffTitleText.appendChild(document.createTextNode('Difference:'));

    diffLegendWrapper.appendChild(diffTitleText);

    const diffValueText = document.createElement('span');

    diffValueText.style.fontSize = '20px';
    diffValueText.style.fontWeight = '600';
    diffValueText.appendChild(
      document.createTextNode(`${parseNumberToSignificant(diff)} ${yAxisTitle}`)
    );

    diffContent.appendChild(diffLegendWrapper);
    diffContent.appendChild(diffValueText);
    tableBody.appendChild(diffContent);
    const dividerEl = document.createElement('div');
    dividerEl.style.width = `100%`;
    dividerEl.style.height = `1px`;
    dividerEl.style.paddingBottom = `8px`;
    dividerEl.style.borderTop = `1px solid ${divider}`;
    tableBody.appendChild(dividerEl);
    // Create content for each point and line
    dataPoints.forEach(({ label, borderColor, data }) => {
      const content = document.createElement('div');
      content.style.display = 'flex';
      content.style.justifyContent = 'space-between';

      const legend = document.createElement('div');
      legend.style.display = 'flex';
      legend.style.gap = '8px';
      legend.style.alignItems = 'center';
      legend.style.flexDirection = 'row';

      const legendBox = document.createElement('span');
      legendBox.style.background = borderColor;
      legendBox.style.height = '4px';
      legendBox.style.width = '12px';
      legendBox.style.display = 'inline-block';
      const title = document.createTextNode(`${label}:`);
      let value = data[dataIndex].y ?? data[dataIndex];

      const valueText = document.createTextNode(
        `${parseNumberToSignificant(parseFloat(value))} ${yAxisTitle}`
      );

      legend.appendChild(legendBox);
      legend.appendChild(title);
      content.appendChild(legend);
      content.appendChild(valueText);
      tableBody.appendChild(content);
    });
    if (secData.datasets?.length) {
      const padding = document.createElement('div');
      padding.style.height = '8px';
      tableBody.appendChild(padding);

      const dividerEl2 = document.createElement('div');
      dividerEl2.style.width = `100%`;
      dividerEl2.style.height = `1px`;
      dividerEl2.style.paddingBottom = `8px`;
      dividerEl2.style.borderTop = `1px solid ${divider}`;
      tableBody.appendChild(dividerEl2);

      let enginesHeader = document.createElement('div');
      enginesHeader.textContent = 'Engines online';
      tableBody.appendChild(enginesHeader);
      secData?.datasets?.forEach(({ data, label, borderColor }) => {
        const legendBox = document.createElement('span');
        legendBox.style.background = borderColor;
        legendBox.style.height = '4px';
        legendBox.style.width = '12px';
        legendBox.style.display = 'inline-block';

        const legend = document.createElement('div');
        legend.style.display = 'flex';
        legend.style.gap = '8px';
        legend.style.alignItems = 'center';
        legend.style.flexDirection = 'row';

        const content = document.createElement('div');
        content.style.display = 'flex';
        content.style.gap = '24px';
        content.style.justifyContent = 'space-between';

        const title = document.createTextNode(`${label}:`);
        const valueText = document.createTextNode(`${data[dataIndex].y?.toFixed(1)} Engines`);
        //
        legend.appendChild(legendBox);
        legend.appendChild(title);
        content.appendChild(legend);
        content.appendChild(valueText);
        tableBody.appendChild(content);
      });
    }
    const tableRoot = tooltipEl.querySelector('div');
    //
    // // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(header);
    tableRoot.appendChild(tableBody);
  }

  let { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
  if (secondary) {
    positionY = secondary.canvas.offsetTop;
  }
  let left = positionX + tooltip.caretX;
  let halfWidth = chart.canvas.width / 2;

  if (left < halfWidth) {
    tooltipEl.style.left = left - 40 + tooltipEl.getBoundingClientRect().width + 'px';
  } else {
    tooltipEl.style.left = left + 40 - tooltipEl.getBoundingClientRect().width + 'px';
  }

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.top = positionY + 150 + 'px';
};

const plugins = [yAxisTitle, hoverLinePlugin];
// Register the required components
ChartJS.register(
  LineElement,
  BarElement,
  PointElement,
  LineController,
  BarController,
  CategoryScale,
  TimeScale,
  LinearScale,
  Filler,

  Tooltip,
  zoomPlugin
);

function BaselineComparisonChart({
  viewBy,
  mainMax,
  secMax,
  loading,
  yAxisTitle,
  mainSeries,
  baseSeries,
  secMainSeries,
  secBaseSeries,
  chartType,
  chartActions,
  mainMinMax,
  dataType,
  hideDailyComp
}) {
  const mainChartRef = useRef(null);
  const secChartRef = useRef(null);
  const [dailyComparison, setDailyComparison] = useState(false);
  const mainDurationHours = useSelector(getMainDurationHours);
  const baselineDurationHours = useSelector(getBaselineDurationHours);
  const { theme } = useTheme();
  const {
    palette: {
      text: { primary },
      neutral: { neutral04 },
      charts: { categoricalFiveColors }
    }
  } = theme;

  const setBackgroundColor = (ctx, dailyComparison, chartType, length, dataBaseline, dataMain) => {
    if (!dailyComparison) return categoricalFiveColors[4];
    const { p0DataIndex, p1DataIndex } = ctx; // p0Index is the current index, and p1DataIndex is the nextIndex

    // Get the differance between the current index and next
    let main = dataMain[p1DataIndex].y - dataMain[p0DataIndex].y;
    let base = dataBaseline[p1DataIndex].y - dataBaseline[p0DataIndex].y;

    if (chartType === BASELINE_CHART_TYPE.BY_ACTIVITIES) {
      main = dataMain[p1DataIndex] - dataMain[p0DataIndex];
      base = dataBaseline[p1DataIndex] - dataBaseline[p0DataIndex];
    }

    let diff = parseFloat(main.toFixed(2)) - parseFloat(base.toFixed(2)); // find the difference between the growth of base and main
    if (p0DataIndex > length || diff === 0) {
      // if both main and base increases/decreases by the same amount then gray
      return categoricalFiveColors[4];
    } else if (diff < 0) {
      // if growth of main is less than the baseline then green
      return categoricalFiveColors[2];
    } else {
      // use red
      return categoricalFiveColors[3];
    }
  };

  const getAccumulatedSeries = (series) => {
    return series
      ?.filter(({ y }, i) => {
        if (y !== 0 && y !== null) {
          if (i === 0) {
            return true;
          }
          if (parseFloat(y) !== parseFloat(series[i - 1].y)) {
            return true;
          }
        }
        return false;
      })
      ?.map(({ y }) => y);
  };

  const getNoDataBorder = (ctx, set, length) => {
    const { p0DataIndex } = ctx;
    let duration = set === 'baseline' ? baselineDurationHours : mainDurationHours;
    if (viewBy === 'day') {
      duration = (duration - 24) / 24; // On day view the dash line should show after last day
    } else {
      duration -= 1; // Remove last hour since function uses math.ceil
    }
    if (p0DataIndex >= duration) {
      return [6, 6];
    }
    if (p0DataIndex > length) {
      return [6, 6];
    }
    return undefined;
  };

  const mainData = useMemo(() => {
    if (!mainSeries?.length || !baseSeries?.length) return { labels: [], datasets: [] };
    let dataBaseline =
      chartType === BASELINE_CHART_TYPE.BY_TIME ? baseSeries : getAccumulatedSeries(baseSeries);
    let dataMain =
      chartType === BASELINE_CHART_TYPE.BY_TIME ? mainSeries : getAccumulatedSeries(mainSeries);
    let categorySet = dataMain.length > dataBaseline.length ? dataMain : dataBaseline;
    let mainLength = dataMain.length;
    let baseLength = dataBaseline.length;

    if (chartType === BASELINE_CHART_TYPE.BY_ACTIVITIES) {
      if (mainLength < baseLength) {
        let lastMain = dataMain[mainLength - 1];
        for (let i = dataMain.length; i < dataBaseline.length; i++) {
          dataMain.push(lastMain);
        }
      } else if (baseLength < mainLength) {
        let lastBase = dataBaseline[baseLength - 1];
        for (let i = baseLength; i < mainLength; i++) {
          dataBaseline.push(lastBase);
        }
      }
    }
    return {
      labels:
        chartType === BASELINE_CHART_TYPE.BY_TIME
          ? categorySet?.map(({ x }) => x)
          : categorySet?.map((val, i) => `${viewBy === 'day' ? 'Day' : 'Hour'} ${i + 1}`),
      datasets: [
        {
          label: 'Baseline',
          data: dataBaseline,
          borderColor: categoricalFiveColors[1],
          borderWidth: 2,
          segment: {
            borderDash: (ctx) => getNoDataBorder(ctx, 'baseline', baseLength)
          },
          spanGaps: true,
          pointHitRadius: 0,
          pointHoverRadius: 0,
          lineTension: 0
        },
        {
          label: 'Selected rig',
          data: dataMain,
          fill: 'origin',
          backgroundColor: 'rgba(97,97,97,0.5)',
          borderColor: categoricalFiveColors[0], // Example color for main line
          borderWidth: 2,
          lineTension: 0,
          pointHitRadius: 0,
          pointHoverRadius: 0,
          spanGaps: true,
          segment: {
            borderDash: (ctx) => getNoDataBorder(ctx, 'main', mainLength),
            backgroundColor: (ctx) =>
              setBackgroundColor(
                ctx,
                dailyComparison,
                chartType,
                mainLength,
                dataBaseline,
                dataMain
              )
          }
        }
      ]
    };
  }, [baseSeries, mainSeries, theme, dailyComparison, chartType, viewBy]);

  const secData = useMemo(() => {
    if (!secMainSeries?.length || !secBaseSeries?.length) return { labels: [], datasets: [] };
    let dataBaseline =
      chartType === BASELINE_CHART_TYPE.BY_TIME
        ? secBaseSeries
        : secBaseSeries?.filter(({ y }) => y !== 0 && y !== null)?.map(({ y }) => y);
    let dataMain =
      chartType === BASELINE_CHART_TYPE.BY_TIME
        ? secMainSeries
        : secMainSeries?.filter(({ y }) => y !== 0 && y !== null)?.map(({ y }) => y);
    let categorySet = dataMain.length > dataBaseline.length ? dataMain : dataBaseline;

    return {
      labels:
        chartType === BASELINE_CHART_TYPE.BY_TIME
          ? undefined
          : categorySet?.map((val, i) => `${viewBy === 'day' ? 'Day' : 'Hour'} ${i + 1}`),
      datasets: [
        {
          label: 'Baseline',
          data: dataBaseline,
          borderColor: categoricalFiveColors[1],
          borderWidth: 2,
          segment: {
            borderDash: (ctx) => getNoDataBorder(ctx, 'baseline')
          },
          stepped: 'after',
          pointHitRadius: 0,
          pointHoverRadius: 0,
          lineTension: 0
        },
        {
          label: 'Selected rig',
          data: dataMain,
          fill: 'origin',
          borderColor: categoricalFiveColors[0], // Example color for main line
          borderWidth: 2,
          lineTension: 0,
          segment: {
            borderDash: (ctx) => getNoDataBorder(ctx, 'main')
          },
          stepped: 'after',
          pointHitRadius: 0,
          pointHoverRadius: 0
        }
      ]
    };
  }, [secBaseSeries, secMainSeries, theme, dailyComparison, chartType, viewBy]);

  const mainOptions = {
    commonUpdate: true,
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 30
      }
    },

    scales: {
      x: {
        offset: false,
        type: 'category',

        labels: mainData.labels,
        ticks: {
          callback: function (value) {
            if (chartType === BASELINE_CHART_TYPE.BY_TIME) {
              let date = moment.utc(this.getLabelForValue(value));
              if (viewBy === 'day') {
                date = moment(this.getLabelForValue(value));
              }
              if (date.clone().hour() === 0 || viewBy === 'day') {
                return date.format('MMM DD');
              }
              return date.format('HH:mm');
            }
            return `${viewBy === 'day' ? 'Day' : 'Hour'} ${value + 1}`;
          },
          color: primary,
          maxRotation: 45,
          minRotation: 45,
          font: {
            size: 10,
            family: ['Roboto', 'helvetica', 'Arial', 'sans-serif'].join(',')
          }
        },
        grid: {
          display: false,
          drawOnChartArea: false,
          drawTicks: false
        }
      },
      y: {
        border: {
          display: false
        },
        grid: {
          tickLength: 0,
          color: `${neutral04}7F`
        },
        afterFit: function (scaleInstance) {
          let stepSize = `${Math.round((mainMax * 1.5) / 5) * 4}`.length;
          scaleInstance.width = stepSize * 8; // sets the width to 100px
        },
        ticks: {
          color: primary,
          font: {
            size: 10,
            family: ['Roboto', 'helvetica', 'Arial', 'sans-serif'].join(',')
          },
          beginAtZero: true,
          callback: (val) => parseInt(val),
          maxTicksLimit: 6, // +1 for zero
          stepSize: Math.round((mainMax * 1.5) / 5),
          max: mainMax * 1.1,
          count: 6,
          precision: 0,
          xMin: 0,
          autoSkip: false
        },
        min: mainMinMax?.min,
        max: mainMinMax?.max,
        type: 'linear',
        position: 'left',
        beginAtZero: !mainMinMax
      }
    },
    elements: {
      line: {
        tension: 0 // Disable bezier curves for straight lines
      },
      point: {
        radius: 0
      }
    },
    tooltips: {
      mode: 'index',
      intersect: false
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: (a) => {
            if (chartType === BASELINE_CHART_TYPE.BY_TIME) {
              return moment
                .utc(a[0].raw.x)
                .format(viewBy === 'day' ? DATE_FORMAT : DATE_TIME_FORMAT);
            }
            return a[0].label;
          }
        },
        enabled: false,
        interaction: {
          intersect: false,
          mode: 'index'
        },
        position: 'nearest',
        external: (context) =>
          externalTooltipHandler(context, theme, yAxisTitle, chartType, mainData, secData, false)
      },

      yAxisTitle: {
        fontColor: primary,
        fontSize: '12px',
        text: yAxisTitle
      },
      hoverLinePlugin: {
        mainChart: mainChartRef.current,
        syncRefs: [secChartRef.current],
        lineColor: primary
      },
      zoom: {
        pan: {
          onPan({ chart }) {
            secChartRef.current.zoomScale(
              'x',
              { min: Math.trunc(chart.scales.x.min), max: Math.trunc(chart.scales.x.max) },
              'none'
            );
          },
          enabled: true,
          mode: 'x'
        },
        limits: {
          x: { min: 'original', max: 'original' }
        },
        zoom: {
          mode: 'x',
          onZoom({ chart }) {
            secChartRef.current.zoomScale(
              'x',
              { min: Math.trunc(chart.scales.x.min), max: Math.trunc(chart.scales.x.max) },
              'none'
            );
          },
          drag: {
            enabled: false
          },
          pinch: {
            enabled: true
          },
          wheel: {
            enabled: true,
            modifierKey: 'ctrl'
          }
        }
      }
    }
  };

  let secOptions = {
    commonUpdate: true,
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 30
      }
    },

    scales: {
      x: {
        offset: false,
        type: 'category',
        time: {
          minUnit: viewBy,
          round: viewBy
        },
        labels: mainData.labels,
        ticks: {
          callback: function (value) {
            if (chartType === BASELINE_CHART_TYPE.BY_TIME) {
              let date = moment.utc(this.getLabelForValue(value));
              if (viewBy === 'day') {
                date = moment(this.getLabelForValue(value));
              }
              if (date.clone().hour() === 0 || viewBy === 'day') {
                return date.format('MMM DD');
              }
              return date.format('HH:mm');
            }
            return `${viewBy === 'day' ? 'Day' : 'Hour'} ${value + 1}`;
          },
          color: primary,
          maxRotation: 45,
          minRotation: 45,
          font: {
            size: 10,
            family: ['Roboto', 'helvetica', 'Arial', 'sans-serif'].join(',')
          }
        },
        grid: {
          display: false,
          drawOnChartArea: false,
          drawTicks: false
        }
      },
      y: {
        border: {
          display: false
        },
        grid: {
          tickLength: 0,
          color: `${neutral04}7F`
        },
        afterFit: function (scaleInstance) {
          let stepSize = `${Math.round((mainMax * 1.5) / 5) * 4}`.length;
          scaleInstance.width = stepSize * 8; // sets the width to 100px
        },
        ticks: {
          color: primary,
          font: {
            size: 10,
            family: ['Roboto', 'helvetica', 'Arial', 'sans-serif'].join(',')
          },
          beginAtZero: true,
          count: secMax + 1,
          autoSkip: false
        },
        max: secMax,
        type: 'linear',
        position: 'left',
        beginAtZero: true
      }
    },
    elements: {
      line: {
        tension: 0 // Disable bezier curves for straight lines
      },
      point: {
        radius: 0
      }
    },
    tooltips: {
      mode: 'index',
      intersect: false
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: (a) => {
            if (chartType === BASELINE_CHART_TYPE.BY_TIME) {
              return moment
                .utc(a[0].raw.x)
                .format(viewBy === 'day' ? DATE_FORMAT : DATE_TIME_FORMAT);
            }
            return a[0].label;
          }
        },
        enabled: false,
        interaction: {
          intersect: false,
          mode: 'index'
        },
        position: 'nearest',
        external: (context) =>
          externalTooltipHandler(
            context,
            theme,
            yAxisTitle,
            chartType,
            mainData,
            secData,
            mainChartRef.current
          )
      },
      yAxisTitle: {
        fontColor: primary,
        fontSize: '12px',
        text: 'Online Engine'
      },
      hoverLinePlugin: {
        isSecondary: true,
        disabled: !secData?.datasets.length,
        mainChart: secChartRef.current,
        syncRefs: [mainChartRef.current],
        lineColor: primary
      },
      zoom: {
        pan: {
          onPan({ chart }) {
            mainChartRef.current.zoomScale(
              'x',
              { min: Math.trunc(chart.scales.x.min), max: Math.trunc(chart.scales.x.max) },
              'none'
            );
          },
          enabled: true,
          mode: 'x'
        },
        limits: {
          x: { min: 'original', max: 'original' }
        },
        zoom: {
          mode: 'xy',
          onZoom({ chart }) {
            mainChartRef.current.zoomScale(
              'x',
              { min: Math.trunc(chart.scales.x.min), max: Math.trunc(chart.scales.x.max) },
              'none'
            );
          },
          drag: {
            enabled: false
          },
          pinch: {
            enabled: true
          },
          wheel: {
            enabled: true,
            modifierKey: 'ctrl'
          }
        }
      }
    }
  };
  useEffect(() => {
    if (dataType === DATA_TYPE.DISCRETE) {
      setDailyComparison(false);
    }
  }, [dataType]);

  return (
    <Stack>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Stack direction={'row'} spacing={1}>
          {!hideDailyComp && (
            <FormControlLabel
              disabled={dataType === DATA_TYPE.DISCRETE}
              control={
                <Switch
                  disabled={dataType === DATA_TYPE.DISCRETE}
                  size={'small'}
                  checked={dailyComparison}
                  onChange={() => setDailyComparison((prev) => !prev)}
                />
              }
              label={<Typography variant={'body2'}>Show daily comparison</Typography>}
            />
          )}
          {dailyComparison && (
            <>
              {/*improved relative to baseline and worsen rel ...*/}
              <LegendValue
                value={'Better than baseline'}
                color={{ name: 'categoricalFiveColors', index: 2 }}
              />
              <LegendValue
                value={'Worse than baseline'}
                color={{ name: 'categoricalFiveColors', index: 3 }}
              />
              <LegendValue
                value={'Equal to baseline'}
                color={{ name: 'categoricalFiveColors', index: 4 }}
              />
            </>
          )}

          <LegendValue value={'Selected rig'} color={{ name: 'categoricalFiveColors', index: 0 }} />
          <LegendValue
            isLine
            value={'Baseline'}
            color={{ name: 'categoricalFiveColors', index: 1 }}
          />
        </Stack>
        <Stack direction={'row'} spacing={2}>
          {chartActions?.map((action) => action)}
        </Stack>
      </Stack>
      <div style={{ height: '330px' }}>
        {loading && <ApexChart type={'bar'} options={{}} loading height={'100%'} />}
        <Chart
          style={{ visibility: !loading ? 'visible' : 'hidden' }}
          id={'chart1'}
          type={'line'}
          ref={mainChartRef}
          data={mainData}
          options={mainOptions}
          plugins={plugins}
        />
      </div>
      <div style={{ display: secData?.datasets.length ? 'block' : 'none', height: '170px' }}>
        <Chart
          plugins={plugins}
          id={'chart2'}
          type={'line'}
          ref={secChartRef}
          data={secData}
          options={secOptions}
        />
      </div>
    </Stack>
  );
}

BaselineComparisonChart.propTypes = {
  chartActions: PropTypes.arrayOf(PropTypes.node),
  mainSeries: PropTypes.arrayOf(PropTypes.shape({ x: PropTypes.string, y: PropTypes.number })),
  baseSeries: PropTypes.arrayOf(PropTypes.shape({ x: PropTypes.string, y: PropTypes.number })),
  secMainSeries: PropTypes.arrayOf(PropTypes.shape({ x: PropTypes.string, y: PropTypes.number })),
  secBaseSeries: PropTypes.arrayOf(PropTypes.shape({ x: PropTypes.string, y: PropTypes.number })),
  categories: PropTypes.arrayOf(PropTypes.string),
  yAxisTitle: PropTypes.string,
  viewBy: PropTypes.oneOf(['hour', 'day']).isRequired,
  chartType: PropTypes.oneOf(Object.values(BASELINE_CHART_TYPE)),
  loading: PropTypes.bool,
  hideDailyComp: PropTypes.bool,
  mainMax: PropTypes.number,
  dataType: PropTypes.oneOf(Object.values(DATA_TYPE)),
  secMax: PropTypes.number,
  mainMinMax: PropTypes.shape({ min: PropTypes.number, max: PropTypes.number })
};

BaselineComparisonChart.defaultProps = {
  mainSeries: [],
  baseSeries: [],
  secMainSeries: [],
  secBaseSeries: [],
  dataType: DATA_TYPE.ACCUMULATED,
  categories: [],
  yAxisTitle: '',
  mainMax: 0,
  secMax: 0,
  chartType: BASELINE_CHART_TYPE.BY_TIME,
  loading: false,
  hideDailyComp: false,
  mainMinMax: null
};

export default BaselineComparisonChart;
