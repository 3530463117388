import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ArrowForwardIcon, Button, Paper, Stack, Typography } from '@esgian/esgianui';
import { DrillingEquipmentChart } from '@components/Charts';
import { SUB_SECTIONS } from '@constants';
import { useSeriesBuilder, useUpdateSection } from '@hooks';
import { useSelector } from 'react-redux';
import { getDisplayUnit } from '@store/features';

function DrillingEquipment({ loading, overviewData }) {
  const { updateMainPageSection } = useUpdateSection();
  const { getEquipmentDataSumSeries } = useSeriesBuilder();
  const displayUnit = useSelector(getDisplayUnit);

  const { series = [], unit = '' } = useMemo(() => {
    if (!overviewData) return {};
    const { clarifyData } = overviewData;
    const { title, mp, hpu, dw, td } = getEquipmentDataSumSeries(clarifyData, displayUnit);

    return { series: [{ data: [mp, hpu, dw, td] }], unit: title };
  }, [displayUnit, overviewData]);
  return (
    <Paper sx={{ p: 2, height: '100%' }}>
      <Stack>
        <Typography variant={'h6'}>Drilling Equipment</Typography>
        <Typography variant={'body2'}>Total in period</Typography>
      </Stack>
      <DrillingEquipmentChart loading={loading} series={series} primaryXAxisLabel={unit} />
      <Stack direction={'row'} justifyContent={'flex-end'}>
        <Button
          onClick={() => updateMainPageSection(SUB_SECTIONS.EQUIPMENT)}
          endIcon={<ArrowForwardIcon />}
          variant={'outlined'}>
          See equipment page
        </Button>
      </Stack>
    </Paper>
  );
}

DrillingEquipment.propTypes = {
  loading: PropTypes.bool,
  overviewData: PropTypes.object
};

DrillingEquipment.defaultProps = {
  loading: false,
  overviewData: null
};

export default DrillingEquipment;
