import React from 'react';
import PropTypes from 'prop-types';
import { Chart } from 'react-chartjs-2';
import './chartJsLoader.css';
import { useTheme } from '@hooks';
import { Box } from '@esgian/esgianui';
function Chartjs({ plugins, chartRef, id, data, options, type, loading, ...props }) {
  if (loading) {
    return (
      <Box sx={{ width: '100%', height: '100%', textAlign: 'center', alignContent: 'center' }}>
        <div className="lds-facebook">
          <Box />
          <Box />
          <Box />
        </div>
      </Box>
    );
  }

  return (
    <Chart
      plugins={plugins}
      ref={chartRef}
      id={id}
      type={type}
      data={data}
      options={options}
      {...props}
    />
  );
}

Chartjs.propTypes = {
  chartRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  plugins: PropTypes.arrayOf(PropTypes.object),
  id: PropTypes.string,
  data: PropTypes.object,
  loading: PropTypes.bool,
  options: PropTypes.object,
  type: PropTypes.oneOf(['line', 'bar'])
};

Chartjs.defaultProps = {
  chartRef: undefined,
  plugins: [],
  id: undefined,
  loading: false,
  data: {},
  options: {},
  type: 'line'
};

export default Chartjs;
