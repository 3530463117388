import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ArrowForwardIcon,
  Button,
  Grid,
  HelpOutlineIcon,
  Paper,
  Stack,
  ToolTip,
  Typography
} from '@esgian/esgianui';
import { DATE_FORMAT, SUB_SECTIONS, WARNINGS_TYPE } from '@constants';
import { useUpdateSection } from '@hooks';
import { EnergyMonitorWarningsChart } from '@components/Charts';
import moment from 'moment-timezone';
import { updateRigSetting } from '@api/Settings';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import { FocusAreaSettingModal } from '@components/Modals';
import {
  FocusAreaSummary,
  PotentialSavingsSummary
} from '@components/SubSections/Analytics/EnergyMonitor/index';
import { getMainPageSlice } from '@store/features/filters/MainPageSlice/MainPageSlice';
import { useMainPageQueries } from '@hooks/usePageQueries/useMainPageQueries';

function EnergyMonitor({ isOverview, analyticsData, loading }) {
  const [settingsOpen, setSettingsOpen] = useState(false);
  const { updateMainPageSection } = useUpdateSection();
  const { startDate, endDate, selectedRig } = useSelector(getMainPageSlice);
  const { resetAnalyticsData } = useMainPageQueries();

  const saveSettings = async (signal, data, theme) => {
    setSettingsOpen(false);
    Swal.showLoading();
    await updateRigSetting(signal, selectedRig, data)
      .then(() => {
        Swal.close();
        resetAnalyticsData();
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Settings saved',
          background: theme.palette.neutral.neutral01,
          color: theme.palette.text.primary,
          showConfirmButton: false,
          timer: 1500
        });
      })
      .catch(() => {
        Swal.fire({
          position: 'center',
          icon: 'error',
          background: theme.palette.neutral.neutral01,
          color: theme.palette.text.primary,
          title: 'Unable to save settings',
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
          setSettingsOpen(true);
        });
      });
  };

  const { warningSummary, savingsSummary, percent } = useMemo(() => {
    if (!analyticsData?.length) return {};
    const warningHours = new Set();
    let seriesObj = {};
    const start = moment.utc(startDate);
    const end = moment.utc(endDate);
    let numbHours = 0;
    while (end.isSameOrAfter(start)) {
      numbHours += 24;
      seriesObj[end.format(DATE_FORMAT)] = { data: [...Array(24)].map(() => new Set()) };
      end.subtract(1, 'day');
    }
    let tempWarningSummary = {};
    let tempSavingsSummary = {
      energySaving: 0,
      fuelSaving: 0,
      co2Saving: 0,
      noxSaving: 0,
      costSaving: 0,
      runTimeSaving: 0
    };
    Object.keys(WARNINGS_TYPE)?.forEach((val) => {
      tempWarningSummary[val] = 0;
    });

    analyticsData.forEach((item) => {
      let start = moment.utc(item.startDate).startOf('hour');
      let end = moment.utc(item.endDate).endOf('hour');

      while (start.isSameOrBefore(end)) {
        warningHours.add(`${start?.format('MM/DD/YYYY-HH')}`);
        seriesObj[start?.format(DATE_FORMAT)]?.data?.[start.hour()]?.add(item.type);
        start.add(1, 'hour');
      }
      tempWarningSummary[item.type] += item.fuelSaving;

      Object.keys(tempSavingsSummary).forEach((key) => {
        tempSavingsSummary[key] += item[key];
      });
    });
    let total = 0;
    Object.values(seriesObj).forEach((value) => {
      value.data.forEach((set) => {
        total += set.size;
      });
    });
    // hours in period multiplied by number of possible warnings
    let time = numbHours * 4;

    return {
      warningSummary: tempWarningSummary,
      savingsSummary: tempSavingsSummary,
      percent: total >= 1 ? (total / time) * 100 : 0
    };
  }, [analyticsData, endDate, startDate]);

  return (
    <Paper sx={{ p: 2, minHeight: '100%' }}>
      <Grid container>
        <Grid item xs={12} sx={{ pb: 3 }}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Stack direction={'row'} spacing={0.5} alignItems={'center'}>
              <Typography variant={'h6'}>Energy monitor</Typography>
              <ToolTip
                title={
                  'The energy monitor indicates time periods when running time, fuel, emissions etc could have been reduced by optimizing the operations. The warning thresholds can be adjusted in the “Focus area settings” menu.'
                }>
                <HelpOutlineIcon sx={{ fontSize: '1rem' }} />
              </ToolTip>
            </Stack>
            {!isOverview && (
              <Button onClick={() => setSettingsOpen(true)} variant={'outlined'}>
                Focus area settings
              </Button>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item sx={{ width: '225px', pr: 5 }}>
              <EnergyMonitorWarningsChart loading={loading} percent={percent} />
            </Grid>
            <Grid item xs>
              <Stack spacing={5}>
                <FocusAreaSummary loading={loading} warningSummary={warningSummary} />
                <PotentialSavingsSummary savingsSummary={savingsSummary} loading={loading} />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        {isOverview && (
          <Grid item xs={12} justifyContent={'flex-end'} mt={2}>
            <Stack direction={'row'} justifyContent={'flex-end'}>
              <Button
                onClick={() => updateMainPageSection(SUB_SECTIONS.ANALYTICS)}
                endIcon={<ArrowForwardIcon />}
                variant={'text'}>
                See analytics page
              </Button>
            </Stack>
          </Grid>
        )}
      </Grid>
      {settingsOpen && (
        <FocusAreaSettingModal
          saveSettings={saveSettings}
          handleClose={() => setSettingsOpen(false)}
        />
      )}
    </Paper>
  );
}

EnergyMonitor.propTypes = {
  isOverview: PropTypes.bool,
  analyticsData: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool
};

EnergyMonitor.defaultProps = { isOverview: false, analyticsData: [], loading: false };

export default EnergyMonitor;
