import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@esgian/esgianui';
import LogDrawer from '@components/Drawers/LogDrawer/LogDrawer';
import { OperationsPeriodicOverviewTooltip } from '@components/Tooltips';
import { BarChartWithCustomTooltip } from '@components/Charts';
import { LegendValue } from '@components';
import { useTheme } from '@hooks';
import moment from 'moment-timezone';

function OperationsPeriodicOverviewChart({ unitTitle, loading, categories, series }) {
  const [showTooltip, setShowTooltip] = useState(null);
  const [logInfo, setLogInfo] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ left: 0, top: 0 });

  const { theme } = useTheme();

  const handleLogClick = useCallback(
    (column) => {
      setLogInfo({ startDate: categories[column], unit: 'day' });
      setShowTooltip(null);
    },
    [categories]
  );

  const {
    palette: {
      charts: { twoColorsSameType }
    }
  } = theme;

  return (
    <>
      <Stack direction={'row'} spacing={2}>
        <LegendValue
          value={'Hotel and utilities'}
          color={{ name: 'twoColorsSameType', index: 0 }}
        />
        <LegendValue value={'Drilling'} color={{ name: 'twoColorsSameType', index: 1 }} />
      </Stack>
      <BarChartWithCustomTooltip
        titleOffsetX={20}
        chartOffsetX={-20}
        yAxisTitle={unitTitle}
        loading={loading}
        customLegend
        stacked
        tooltipId={'opo-custom-tooltip'}
        setTooltipPosition={setTooltipPosition}
        colorPalette={'twoColorsSameType'}
        setShowTooltip={setShowTooltip}
        id={'operations-periodic-overview'}
        categories={categories}
        showTooltip={showTooltip}
        series={series}
        customTooltip={
          <OperationsPeriodicOverviewTooltip
            date={`${moment(categories[showTooltip]).format('DD/MMM/YYYY')}`}
            series={series}
            unitTitle={unitTitle}
            legendColors={twoColorsSameType}
            handleLogClick={handleLogClick}
            column={showTooltip}
            position={tooltipPosition}
          />
        }
      />
      <LogDrawer handleClose={() => setLogInfo(null)} open={!!logInfo} logInfo={logInfo} />
    </>
  );
}

OperationsPeriodicOverviewChart.propTypes = {
  loading: PropTypes.bool,
  unitTitle: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.string),
  series: PropTypes.arrayOf(PropTypes.object)
};

OperationsPeriodicOverviewChart.defaultProps = {
  loading: false,
  unitTitle: '',
  categories: [],
  series: []
};

export default OperationsPeriodicOverviewChart;
