import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Stack, Typography } from '@esgian/esgianui';
import { useTheme } from '@hooks';
import { useTimezone } from '@hooks/useTimezone';
import { TIMESTAMP, UTC_TIMESTAMP } from '@constants';
import CanvasTimeSeriesChart from '@components/Charts/CanvasTimeSeriesChart/CanvasTimeSeriesChart';

function EngineAverageChartsWrapper({
  avgLoadChartRef,
  avgSfocChartRef,
  avgLoadSyncRefs,
  avgSfocSyncRefs,
  loading,
  rowData,
  chartData
}) {
  const { selectedTimeZone } = useTimezone();
  const { theme } = useTheme();
  const {
    palette: {
      charts: { sixColorsLine }
    }
  } = theme;
  const { seriesLoad = [], seriesSfoc = [] } = useMemo(() => {
    if (!chartData) return {};
    const { numOfEngines } = chartData;

    let startDate = moment.parseZone(rowData.startDate).subtract(30, 'minute');
    let endDate = moment.parseZone(rowData.endDate).add(30, 'minutes');

    let tempSeriesLoad = [];
    let tempSeriesSFOC = [];
    for (let i = 1; i <= numOfEngines; i++) {
      tempSeriesLoad.push({
        spanGaps: false,
        borderColor: sixColorsLine[i - 1],
        label: `Engine ${i}`,
        data: []
      });
      tempSeriesSFOC.push({
        spanGaps: false,
        borderColor: sixColorsLine[i - 1],
        label: `Engine ${i}`,
        data: []
      });
    }
    tempSeriesLoad.push({
      spanGaps: false,
      borderColor: sixColorsLine[5],
      label: `Optimal average engine load`,
      data: []
    });
    tempSeriesSFOC.push({
      spanGaps: false,
      borderColor: sixColorsLine[5],
      label: `Average optimal engine SFOC`,
      data: []
    });
    let count = 0;
    let diff = endDate.diff(startDate, 'minute');
    while (startDate.isSameOrBefore(endDate)) {
      let utcTimestamp = startDate.clone().format(UTC_TIMESTAMP);
      const localTime = startDate.clone().tz(selectedTimeZone);
      const offset = localTime.format('Z');

      let dayKey = `${startDate.clone().format(TIMESTAMP)}${offset}`;
      let timestamp = moment(utcTimestamp).valueOf();

      for (let i = 0; i <= numOfEngines - 1; i++) {
        tempSeriesLoad[i].data.push({
          x: timestamp,
          y: chartData[`Eng${i + 1}Lavg`][dayKey] || null
        });
        tempSeriesSFOC[i].data.push({
          x: timestamp,
          y: chartData[`Eng${i + 1}SFOC`][dayKey] || null
        });
      }
      if (count <= 29 || count >= diff - 29) {
        tempSeriesLoad[numOfEngines].data.push({ x: timestamp, y: null });
        tempSeriesSFOC[numOfEngines].data.push({ x: timestamp, y: null });
      } else {
        tempSeriesLoad[numOfEngines].data.push({
          x: timestamp,
          y: chartData['EngLsim'][dayKey] || null
        });
        tempSeriesSFOC[numOfEngines].data.push({
          x: timestamp,
          y: chartData['OptRigSFOC'][dayKey] || null
        });
      }
      count++;
      startDate = startDate.add(1, 'minute');
    }
    return { seriesLoad: tempSeriesLoad, seriesSfoc: tempSeriesSFOC };
  }, [rowData, chartData]);

  return (
    <Stack spacing={2}>
      <Stack>
        <Typography variant={'subtitle1'} bold sx={{ pb: 2 }}>
          Engine Average load
        </Typography>
        <CanvasTimeSeriesChart
          maxY={100}
          minY={0}
          loading={loading}
          unit={'%'}
          height={'175px'}
          syncRefs={avgLoadSyncRefs}
          chartRef={avgLoadChartRef}
          series={seriesLoad}
          id={'engine-avg-load'}
        />
      </Stack>
      <Stack>
        <Typography variant={'subtitle1'} bold sx={{ pb: 2 }}>
          Engine Average SFOC
        </Typography>
        <CanvasTimeSeriesChart
          maxY={350}
          minY={150}
          unit={'g/kWh'}
          loading={loading}
          height={'175px'}
          chartRef={avgSfocChartRef}
          syncRefs={avgSfocSyncRefs}
          series={seriesSfoc}
          id={'engine-avg-sfoc'}
        />
      </Stack>
    </Stack>
  );
}

EngineAverageChartsWrapper.propTypes = {
  rowData: PropTypes.object,
  loading: PropTypes.bool,
  chartData: PropTypes.object,
  avgLoadChartRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]).isRequired,
  avgSfocChartRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]).isRequired,
  avgLoadSyncRefs: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })])
  ).isRequired,
  avgSfocSyncRefs: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })])
  ).isRequired
};

EngineAverageChartsWrapper.defaultProps = {
  rowData: null,
  chartData: null,
  loading: false
};

export default EngineAverageChartsWrapper;
