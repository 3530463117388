import { BaseApiRequest } from '@api/BaseApi';
import moment from 'moment-timezone';
import { getToken } from '@store/helpers';
import { WARNINGS_TYPE } from '@constants';
import { toast } from 'react-toastify';
import { getOffset } from '@api/helpers';

export const fetchPowerMgmtData = (signal, { selectedRig, startDate, endDate }, rollup) => {
  return BaseApiRequest(signal, `/PowerMgmt`, 'gpr', 'POST', {
    rigName: selectedRig.rigName,
    rigId: selectedRig.rigId,
    startDate,
    endDate,
    rollup: rollup,
    token: getToken(),
    offset: getOffset(startDate)
  }).catch((error) => {
    if (!signal.aborted) {
      toast.error(`Power mgmt: ${error.errors[0]}`, {
        position: 'top-right'
      });
    }
  });
};

export const fetchOverviewKeyFiguresData = (
  signal,
  { selectedRig, startDate, endDate },
  rollup
) => {
  return BaseApiRequest(signal, `/RigOverviewKeyFigures`, 'gpr', 'POST', {
    rigName: selectedRig.rigName,
    rigId: selectedRig.rigId,
    startDate,
    endDate,
    rollup: rollup,
    token: getToken(),
    offset: getOffset(startDate)
  }).catch((error) => {
    if (!signal.aborted) {
      toast.error(`Overview Key Figures: ${error.errors[0]}`, {
        position: 'top-right'
      });
    }
  });
};

export const fetchOverviewKeyFiguresDataV2 = (
  signal,
  { selectedRig, startDate, endDate },
  rollup
) => {
  return BaseApiRequest(signal, `/RigOverviewKeyFigures/v2`, 'gpr', 'POST', {
    rigName: selectedRig.rigName,
    rigId: selectedRig.rigId,
    startDate,
    endDate,
    rollup: rollup,
    token: getToken(),
    offset: getOffset(startDate)
  }).catch((error) => {
    if (!signal.aborted) {
      toast.error(`Overview Key Figures: ${error.errors[0]}`, {
        position: 'top-right'
      });
    }
  });
};

export const fetchOverviewData = (signal, { selectedRig, startDate, endDate }, rollup) => {
  return BaseApiRequest(signal, `/RigOverview`, 'gpr', 'POST', {
    rigName: selectedRig.rigName,
    rigId: selectedRig.rigId,
    startDate,
    endDate,
    rollup: rollup,
    token: getToken(),
    offset: getOffset(startDate)
  }).catch((error) => {
    if (!signal.aborted) {
      toast.error(`Overview: ${error.errors[0]}`, {
        position: 'top-right'
      });
    }
  });
};

export const fetchEquipmentData = (signal, { selectedRig, startDate, endDate }, rollup) => {
  return BaseApiRequest(signal, `/Equipment/v2`, 'gpr', 'POST', {
    rigName: selectedRig.rigName,
    rigId: selectedRig.rigId,
    startDate,
    endDate,
    rollup: rollup,
    token: getToken(),
    offset: getOffset(startDate)
  }).catch((error) => {
    if (!signal.aborted) {
      toast.error(`Equipment: ${error.errors[0]}`, {
        position: 'top-right'
      });
    }
  });
};

export const fetchDrillingActivitiesData = (
  signal,
  { selectedRig, startDate, endDate },
  rollup
) => {
  return BaseApiRequest(signal, `/DrillingActivities`, 'gpr', 'POST', {
    rigName: selectedRig.rigName,
    rigId: selectedRig.rigId,
    startDate,
    endDate,
    rollup: rollup,
    token: getToken(),
    offset: getOffset(startDate)
  }).catch((error) => {
    if (!signal.aborted) {
      toast.error(`Drilling activities: ${error.errors[0]}`, {
        position: 'top-right'
      });
    }
  });
};
export const fetchDrillingActivitiesV2Data = (
  signal,
  { selectedRig, startDate, endDate },
  rollup
) => {
  return BaseApiRequest(signal, `/DrillingActivities/v2`, 'gpr', 'POST', {
    rigName: selectedRig.rigName,
    rigId: selectedRig.rigId,
    startDate,
    endDate,
    rollup: rollup,
    token: getToken(),
    offset: getOffset(startDate)
  }).catch((error) => {
    if (!signal.aborted) {
      toast.error(`Drilling activities: ${error.errors[0]}`, {
        position: 'top-right'
      });
    }
  });
};

export const fetchAnalyticsData = (signal, { selectedRig, startDate, endDate }, rollup) => {
  return BaseApiRequest(signal, `/Analytics/v2`, 'gpr', 'POST', {
    rigName: selectedRig.rigName,
    rigId: selectedRig.rigId,
    startDate,
    endDate,
    rollup: rollup,
    token: getToken(),
    offset: getOffset(startDate)
  }).catch((error) => {
    if (!signal.aborted) {
      toast.error(`Analytics: ${error.errors[0]}`, {
        position: 'top-right'
      });
    }
  });
};

export const fetchWarningData = (
  signal,
  selectedRig,
  startDate,
  endDate,
  focusAreaStartDate,
  focusAreaEndDate,
  rollup,
  type = WARNINGS_TYPE.TooManyHPU
) => {
  let uri = type === WARNINGS_TYPE.TooManyHPU ? 'HPUFocusArea/v2' : 'EngineFocusArea/v2';
  return BaseApiRequest(signal, `/${uri}`, 'gpr', 'POST', {
    rigName: selectedRig.rigName,
    rigId: selectedRig.rigId,
    startDate: startDate,
    endDate: endDate,
    focusAreaStartDate: `${focusAreaStartDate}${getOffset(startDate)}`,
    focusAreaEndDate: `${focusAreaEndDate}${getOffset(startDate)}`,
    rollup: rollup,
    token: getToken(),
    offset: getOffset(startDate)
  }).catch((error) => {
    if (!signal.aborted) {
      toast.error(`Warning ${uri}: ${error.errors[0]}`, {
        position: 'top-right'
      });
    }
  });
};
