import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ApexChart } from '@esgian/esgianui';
import GroupedBarsWithCustomTooltip from '../GroupedBarsWithCustomTooltip';
import moment from 'moment/moment';
import { UTC_TIMESTAMP } from '@constants';
import { getValue } from '@helpers';

function EnginePercentageLoadChart({ loading, powerMgmtData, setSelectedDate }) {
  const {
    categories = [],
    mainSeries = [],
    secondarySeries = []
  } = useMemo(() => {
    if (!powerMgmtData) return {};
    const {
      clarifyData: { EngOn = {}, EngLavg = {} }
    } = powerMgmtData;
    return {
      categories: Object.keys(EngLavg || {})?.map((val) => {
        return moment.parseZone(val).format(UTC_TIMESTAMP);
      }),
      mainSeries: [
        {
          name: 'Load',
          data: Object.values(EngLavg || {})?.map((val) => parseInt(getValue(val)))
        }
      ],
      secondarySeries: [
        {
          name: 'Engine online',
          data: Object.values(EngOn || {})?.map((val) => getValue(val)?.toFixed(2) || 0)
        }
      ]
    };
  }, [powerMgmtData]);

  if (loading) {
    return <ApexChart newLoadingAnimation type={'bar'} options={{}} height={500} loading />;
  }
  return (
    <GroupedBarsWithCustomTooltip
      secondaryMax={powerMgmtData?.numOfEngines || null}
      setSelectedDate={setSelectedDate}
      tooltipId={'epl-tooltip'}
      mainSeries={mainSeries}
      secondarySeries={secondarySeries}
      categories={categories}
    />
  );
}

EnginePercentageLoadChart.propTypes = {
  setSelectedDate: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  powerMgmtData: PropTypes.object
};

EnginePercentageLoadChart.defaultProps = {
  loading: false,
  powerMgmtData: null
};

export default EnginePercentageLoadChart;
