/* eslint-disable no-prototype-builtins */
import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Paper, Stack } from '@esgian/esgianui';
import LogDrawer from '@components/Drawers/LogDrawer/LogDrawer';
import { PeriodicOverviewWithDetailsChart } from '@components/Charts';
import { useSeriesBuilder, useDisplayUnit } from '@hooks';
import moment from 'moment-timezone';
import { fetchEquipmentData } from '@api/Rig';
import { API_ROLLUP, DATE_TIMEZONE_FORMAT, UTC_TIMESTAMP } from '@constants';
import { LegendValue } from '@components';
import { useSelector } from 'react-redux';
import { getDisplayUnit } from '@store/features';
import { getMainPageRig } from '@store/features/filters/MainPageSlice/MainPageSlice';
import { useTimezone } from '@hooks/useTimezone';
import DailyRegeneratedEnergyDrawworks from '../../Equipment/DailyRegeneratedEnergyDrawworks';
import { useQuery } from '@tanstack/react-query';
import { getValue } from '@helpers';

const updateDateTimeForKey = (obj, timezone) => {
  let updated = {};
  Object.keys(obj || {})?.forEach((key) => {
    const newKey = moment.tz(key, timezone).format('YYYY-MM-DDTHH:mm:ss[Z]');
    updated[newKey] = obj[key];
  });
  return updated;
};

const processAllKeys = (data = {}, timezone) => {
  const updatedData = {};
  for (let key in data) {
    if (data?.hasOwnProperty(key)) {
      updatedData[key] = updateDateTimeForKey(data?.[key], timezone);
    }
  }
  return updatedData;
};

function PeriodicOverviewDetails({ equipmentData, loading }) {
  const [selectedView, handleViewChange] = useState('hour');

  const [selectedColumn, setSelectedColumn] = useState(0);
  const [logInfo, setLogInfo] = useState(null);
  const { getSeriesData, getEquipmentDataFields, getMaxOfStackedSeries } = useSeriesBuilder();
  const { getUnitTitle } = useDisplayUnit();
  const displayUnit = useSelector(getDisplayUnit);
  const selectedRig = useSelector(getMainPageRig);
  const { selectedTimeZone } = useTimezone();

  const buildSecondarySeries = useCallback((clarifyData) => {
    const { MPTotP, HPURLtotP, DWtotP, TDtotP } = clarifyData;
    let tempSeries = [
      {
        name: 'MP',
        data: Object.keys(MPTotP)?.map((key) => {
          let value = getValue(MPTotP?.[key], false);
          return [moment.parseZone(key).valueOf(), value];
        })
      },
      {
        name: 'HPU',
        data: Object.keys(HPURLtotP)?.map((key) => {
          let value = getValue(HPURLtotP?.[key], false);
          return [moment.parseZone(key).valueOf(), value];
        })
      },
      {
        name: 'TD',
        data: Object.keys(TDtotP)?.map((key) => {
          let value = getValue(TDtotP?.[key], false);
          return [moment.parseZone(key).valueOf(), value];
        })
      },
      {
        name: 'DW',
        data: Object.keys(DWtotP)?.map((key) => {
          let value = getValue(DWtotP?.[key], false);
          return [moment.parseZone(key).valueOf(), value];
        })
      }
    ];
    return {
      categories: Object.keys(TDtotP)?.map((val) => {
        return moment.parseZone(val).format(UTC_TIMESTAMP);
      }),
      series: tempSeries
    };
  }, []);

  const buildSeriesData = useCallback(
    (clarifyData, isStacked = false) => {
      const { mp, hpu, dw, td } = getEquipmentDataFields(clarifyData, displayUnit);
      const {
        max: mpMax,
        defaultArray: mpDefault,
        convertedArray: mpConverted
      } = getSeriesData(mp, displayUnit);
      const {
        max: dwMax,
        defaultArray: dwDefault,
        convertedArray: dwConverted
      } = getSeriesData(dw, displayUnit);
      const {
        max: tdMax,
        defaultArray: tdDefault,
        convertedArray: tdConverted
      } = getSeriesData(td, displayUnit);
      const {
        max: hpuMax,
        defaultArray: hpuDefault,
        convertedArray: hpuConverted
      } = getSeriesData(hpu, displayUnit);
      let max = Math.max(...[mpMax, hpuMax, dwMax, tdMax]);
      if (isStacked) {
        max = getMaxOfStackedSeries([mpDefault, hpuDefault, tdDefault, dwDefault]);
      }
      const { title, converted } = getUnitTitle(max);
      return {
        unit: title,
        categories: Object.keys(mp).map((key) => moment.parseZone(key).format(UTC_TIMESTAMP)),
        series: [
          { name: 'MP', data: converted ? mpConverted : mpDefault },
          { name: 'HPU', data: converted ? hpuConverted : hpuDefault },
          { name: 'TD', data: converted ? tdConverted : tdDefault },
          { name: 'DW', data: converted ? dwConverted : dwDefault }
        ]
      };
    },
    [displayUnit]
  );

  const {
    unit,
    primaryCategories = [],
    primarySeries
  } = useMemo(() => {
    if (!equipmentData) return {};
    const { clarifyData } = equipmentData;
    const {
      unit,
      categories: primaryCategories,
      series: primarySeries
    } = buildSeriesData(clarifyData, true);
    setSelectedColumn(0);
    return {
      unit: unit,
      primaryCategories: primaryCategories,
      primarySeries: primarySeries
    };
  }, [equipmentData, displayUnit]);

  const secondaryData = useQuery({
    queryKey: [
      'equipment',
      {
        primData: JSON.stringify(primaryCategories),
        primaryCategories,
        selectedView,
        selectedColumn,
        selectedRig
      }
    ],
    enabled: !!selectedRig && !!primaryCategories.length,
    placeholderData: null,
    queryFn: ({ signal }) => {
      const rollup = selectedView === 'hour' ? API_ROLLUP.HOUR : API_ROLLUP.MIN;
      // Convert the UTC date to the specified timezone
      let start = moment.tz(moment.parseZone(primaryCategories[selectedColumn]), selectedTimeZone);
      // let end = moment.parseZone(primaryCategories[selectedColumn]);
      let end = moment.tz(primaryCategories[selectedColumn], selectedTimeZone);

      // Determine the offset and adjust dates if negative
      if (start.utcOffset() < 0) {
        start.add(1, 'day');
        end.add(1, 'day');
      }
      // Set the startDate to the start of selectedDay and endDate to the end of next day
      start.startOf('day');
      end.endOf('day');

      if (selectedView === 'hour') {
        if (selectedColumn > 0) {
          start = start.subtract(1, 'day');
        }
        if (selectedColumn !== primaryCategories.length - 1) {
          end = end.add(1, 'day');
        }
      }

      if (end.isAfter(moment.tz(selectedTimeZone))) {
        end = moment().tz(selectedTimeZone);
      }

      const startDate = start.format(DATE_TIMEZONE_FORMAT);
      const endDate = end.format(DATE_TIMEZONE_FORMAT);

      return fetchEquipmentData(signal, { selectedRig, startDate, endDate }, rollup)
        .then((result) => {
          const { clarifyData } = result;
          return processAllKeys(clarifyData, selectedTimeZone);
        })
        .catch(() => {
          return null;
        });
    }
  });

  const { secondarySeries = [], secondaryCategories = [] } = useMemo(() => {
    if (!secondaryData.data) return {};

    const { categories, series } = buildSecondarySeries(secondaryData.data);
    return { secondaryCategories: categories, secondarySeries: series };
  }, [secondaryData, displayUnit]);
  return (
    <Paper sx={{ p: 2 }}>
      <PeriodicOverviewWithDetailsChart
        secondaryPrimaryChart={
          <DailyRegeneratedEnergyDrawworks
            setSelectedColumn={setSelectedColumn}
            loading={loading}
            categories={primaryCategories}
            equipmentData={equipmentData}
          />
        }
        secondaryCategories={secondaryCategories}
        detailsTitle={'Power details'}
        secondaryUnit={'kW'}
        // secondaryChartStacked
        unit={unit}
        loadingSecondarySeries={secondaryData.isFetching}
        loading={loading}
        handleViewChange={handleViewChange}
        selectedView={selectedView}
        secondaryChartType={'line'}
        selectedColumn={selectedColumn}
        setSelectedColumn={setSelectedColumn}
        secondarySeries={secondarySeries}
        handleLogClick={setLogInfo}
        primaryCategories={primaryCategories}
        primarySeries={primarySeries}
        xAxisType={'datetime'}
        legend={
          <Stack spacing={1} direction={'row'}>
            <LegendValue value={'MP'} color={{ name: 'fourColorsStacked', index: 0 }} />
            <LegendValue value={'HPU'} color={{ name: 'fourColorsStacked', index: 1 }} />
            <LegendValue value={'TD'} color={{ name: 'fourColorsStacked', index: 2 }} />
            <LegendValue value={'DW'} color={{ name: 'fourColorsStacked', index: 3 }} />
          </Stack>
        }
      />
      <LogDrawer handleClose={() => setLogInfo(null)} open={!!logInfo} logInfo={logInfo} />
    </Paper>
  );
}

PeriodicOverviewDetails.propTypes = {
  loading: PropTypes.bool,
  equipmentData: PropTypes.object
};

PeriodicOverviewDetails.defaultProps = {
  loading: false,
  equipmentData: null
};

export default PeriodicOverviewDetails;
