/**
 * Plugin that removes the tooltip from synced charts when the mouse leaves the chart
 * @param syncRefs array of references to synced charts
 * */
export const syncTooltipHiderPlugin = (syncRefs) => {
  return {
    beforeEvent(chart, args) {
      const event = args.event;
      if (event.type === 'mouseout') {
        syncRefs?.forEach(({ current }) => {
          const tooltip = current?.tooltip;
          if (tooltip) {
            tooltip.setActiveElements([]);
          }
        });
      }
    }
  };
};
