import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton, Stack, Typography } from '@esgian/esgianui';

function CategoryKeyValueDisplay({ value, category, loading, width }) {
  return (
    <Stack sx={{ textAlign: 'left', flex: '1 0 40%', width, p: 2 }}>
      <Typography variant={'subtitle1'} bold>
        {loading ? <Skeleton sx={{ minWidth: '2em' }} /> : `${value}%`}
      </Typography>
      <Typography variant={'overline'} sx={{ textTransform: 'capitalize' }}>
        {loading ? <Skeleton sx={{ minWidth: '2em' }} /> : `${category}`}
      </Typography>
    </Stack>
  );
}

CategoryKeyValueDisplay.propTypes = {
  value: PropTypes.number,
  category: PropTypes.string,
  loading: PropTypes.bool,
  width: PropTypes.string
};

CategoryKeyValueDisplay.defaultProps = {
  value: null,
  category: null,
  loading: false,
  width: 'min-content'
};

export default CategoryKeyValueDisplay;
