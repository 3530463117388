import { BaseApiRequest } from '@api/BaseApi';
import { getToken } from '@store/helpers';
import { getOffset, objectToQuery } from '@api/helpers';
import download from 'downloadjs';
import { toast } from 'react-toastify';

export const fetchPdfReport = (signal, selectedRig, selectedWell) => {
  let params = {
    rigId: selectedRig.rigId,
    rigName: selectedRig.rigName,
    wellName: selectedWell.wellname,
    startDate: selectedWell.starttime,
    offset: getOffset(selectedWell.starttime),
    endDate: selectedWell.endtime,
    token: getToken()
  };

  return BaseApiRequest(signal, `/GetWellEmissionsFile`, 'gpr', 'download', params)
    .then((respBlob) => {
      download(respBlob, `Well Emissions Report ${selectedWell.wellname}.pdf`, 'application/pdf');
      toast.success(`Downloaded complete`, {
        type: 'success',
        position: 'top-right'
      });
    })
    .catch(() => {
      toast.error(`Unable to download PDF`, {
        type: 'error',
        position: 'top-right'
      });
    });
};
