import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import SimpleBarChart from '../SimpleBarChart';
import KeyValueTooltip from '@components/Charts/KeyValueTooltip';
import { useDisplayUnit } from '@hooks';

function DrillingEquipmentChart({ loading, series, primaryXAxisLabel }) {
  const { displayUnit } = useDisplayUnit();
  const categories = useMemo(() => ['MP', 'HPU', 'DW', 'TD'], []);
  return (
    <SimpleBarChart
      primaryTitleOffsetY={330}
      primaryXAxisLabel={primaryXAxisLabel}
      loading={loading}
      vertical
      categories={categories}
      series={series}
      customTooltipContent={(series, dataPointIndex) => (
        <KeyValueTooltip
          displayUnit={displayUnit}
          label={categories[dataPointIndex]}
          value={series[0][dataPointIndex]}
          unit={primaryXAxisLabel}
        />
      )}
    />
  );
}
DrillingEquipmentChart.propTypes = {
  series: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  primaryXAxisLabel: PropTypes.string
};

DrillingEquipmentChart.defaultProps = {
  series: [],
  loading: false,
  primaryXAxisLabel: ''
};

export default DrillingEquipmentChart;
