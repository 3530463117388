import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Paper, Stack, Typography } from '@esgian/esgianui';
import { HeatMap } from '@components/Charts';
import { LegendValue } from '@components';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { DATE_FORMAT } from '@constants';
import { getMainPageSlice } from '@store/features/filters/MainPageSlice/MainPageSlice';
import { useTimezone } from '@hooks/useTimezone';
function FocusAreasHeatMap({ analyticsData, loading }) {
  const { startDate, endDate } = useSelector(getMainPageSlice);
  const { selectedTimeZone } = useTimezone();

  const series = useMemo(() => {
    const start = moment.utc(startDate);
    const end = moment.utc(endDate);
    let seriesObj = {};
    while (end.isSameOrAfter(start)) {
      seriesObj[end.format(DATE_FORMAT)] = { data: [...Array(24)].map(() => new Set()) };
      end.subtract(1, 'day');
    }
    analyticsData.forEach(({ type, startDate: s, endDate: e }) => {
      let eventStart = moment.parseZone(s).startOf('hour');
      let eventEnd = moment.parseZone(e).endOf('hour');
      while (eventStart.isSameOrBefore(eventEnd)) {
        seriesObj[eventStart.clone().format(DATE_FORMAT)]?.data?.[eventStart.hour()].add(type);
        eventStart.add(1, 'hour');
      }
    });
    let tempSeries = [];
    for (const [key, value] of Object.entries(seriesObj)) {
      tempSeries.push({
        name: moment(key).format('DD/MMM'),
        data: value.data.map((set) => set.size)
      });
    }

    return tempSeries;
  }, [analyticsData, startDate, endDate, selectedTimeZone]);

  return (
    <Paper sx={{ p: 2 }}>
      <Stack>
        <Stack spacing={2}>
          <Typography variant={'h6'}>Focus areas - heat map</Typography>
          <Stack direction={'row'} spacing={2}>
            {[...Array(5)]?.map((color, index) => (
              <LegendValue
                key={`${index}-warning-legend`}
                value={index === 0 ? 'Normal' : `${index} Warnings`}
                color={{ name: 'heatmapChartColors', index: index }}
              />
            ))}
          </Stack>
        </Stack>
        <HeatMap loading={loading} series={series} />
      </Stack>
    </Paper>
  );
}

FocusAreasHeatMap.propTypes = {
  analyticsData: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool
};

FocusAreasHeatMap.defaultProps = {
  analyticsData: [],
  loading: false
};

export default FocusAreasHeatMap;
