import { useQueries, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getMainPageSlice } from '@store/features/filters/MainPageSlice/MainPageSlice';
import {
  fetchAnalyticsData,
  fetchDrillingActivitiesV2Data,
  fetchEquipmentData,
  fetchOverviewData,
  fetchOverviewKeyFiguresData,
  fetchPowerMgmtData
} from '@api/Rig';
import { API_ROLLUP, BASELINE_SUB_SECTIONS } from '@constants';
import { useTimezone } from '@hooks/useTimezone';
import { useCallback, useMemo } from 'react';
import { getLookupRigs } from '@store/features';
import { getBaselineComparisonSlice } from '@store/features/filters/BaselineComparisonSlice/BaselineComparisonSlice';

export const useBaselineQueries = () => {
  const queryClient = useQueryClient(); // Access queryClient instance
  const lookupRigs = useSelector(getLookupRigs);
  const { selectedBaseline, graphDisplays } = useSelector(getBaselineComparisonSlice);
  const { selectedRig, startDate: start, endDate: end } = useSelector(getMainPageSlice);
  const { calculateDates } = useTimezone();
  const { dateStart: mainStart, dateEnd: mainEnd } = calculateDates(start, end);
  const { dateStart: baseStart, dateEnd: baseEnd } = calculateDates(
    selectedBaseline.startDate,
    selectedBaseline.endDate
  );

  const visibleSection = useMemo(() => {
    let res = [];
    graphDisplays.forEach(({ graphName, visible }) => {
      if (visible) {
        res.push(graphName);
      }
    });
    return res;
  }, [graphDisplays]);

  const baselineRig = useMemo(() => {
    if (lookupRigs.length) {
      return lookupRigs.find(({ rigId }) => rigId === selectedBaseline.rigId) ?? null;
    }
    return null;
  }, [lookupRigs, selectedBaseline]);

  const baselineKeyFiguresQueries = useQueries({
    queries: [
      {
        queryKey: [
          'baseline',
          'keyFig',
          'main',
          { selectedRig: selectedRig, startDate: mainStart, endDate: mainEnd }
        ],
        enabled: !!selectedRig && !!mainStart && !!mainEnd,
        placeholderData: null,
        queryFn: ({ queryKey, signal }) => {
          return fetchOverviewKeyFiguresData(signal, queryKey[3], API_ROLLUP.HOUR)
            .then((result) => result)
            .catch(() => null);
        }
      },
      {
        queryKey: ['baseline', 'keyFig', 'base', { selectedBaseline: selectedBaseline }],
        enabled: !!selectedBaseline && !!baselineRig,
        placeholderData: null,
        queryFn: ({ signal }) => {
          if (baselineRig) {
            return fetchOverviewKeyFiguresData(
              signal,
              { selectedRig: baselineRig, startDate: baseStart, endDate: baseEnd },
              API_ROLLUP.HOUR
            )
              .then((result) => result)
              .catch(() => null);
          }
          return null;
        }
      }
    ]
  });
  const baselineOverviewQueries = useQueries({
    queries: [
      {
        queryKey: [
          'baseline',
          'overview',
          'main',
          { selectedRig: selectedRig, startDate: mainStart, endDate: mainEnd }
        ],
        enabled:
          !!selectedRig &&
          !!mainStart &&
          !!mainEnd &&
          !!visibleSection.includes(BASELINE_SUB_SECTIONS.PERIODIC_OVERVIEW),
        placeholderData: null,
        queryFn: ({ queryKey, signal }) => {
          return fetchOverviewData(signal, queryKey[3], API_ROLLUP.HOUR)
            .then((result) => result.clarifyData)
            .catch(() => null);
        }
      },
      {
        queryKey: ['baseline', 'overview', 'base', { selectedBaseline: selectedBaseline }],
        enabled:
          !!selectedBaseline &&
          !!baselineRig &&
          !!visibleSection.includes(BASELINE_SUB_SECTIONS.PERIODIC_OVERVIEW),
        placeholderData: null,
        queryFn: ({ signal }) => {
          if (baselineRig) {
            return fetchOverviewData(
              signal,
              { selectedRig: baselineRig, startDate: baseStart, endDate: baseEnd },
              API_ROLLUP.HOUR
            )
              .then((result) => result.clarifyData)
              .catch(() => null);
          }
          return null;
        }
      }
    ]
  });
  const baselineDrillingQueries = useQueries({
    queries: [
      {
        queryKey: [
          'baseline',
          'drilling',
          'main',
          { selectedRig: selectedRig, startDate: mainStart, endDate: mainEnd }
        ],
        enabled:
          !!selectedRig &&
          !!mainStart &&
          !!mainEnd &&
          !!visibleSection.includes(BASELINE_SUB_SECTIONS.DRILLING_ACTIVITIES),
        placeholderData: null,
        queryFn: ({ queryKey, signal }) => {
          return fetchDrillingActivitiesV2Data(signal, queryKey[3], API_ROLLUP.HOUR)
            .then((result) => result)
            .catch(() => null);
        }
      },
      {
        queryKey: ['baseline', 'drilling', 'base', { selectedBaseline: selectedBaseline }],
        enabled:
          !!selectedBaseline &&
          !!baselineRig &&
          !!visibleSection.includes(BASELINE_SUB_SECTIONS.DRILLING_ACTIVITIES),
        placeholderData: null,
        queryFn: ({ signal }) => {
          if (baselineRig) {
            return fetchDrillingActivitiesV2Data(
              signal,
              { selectedRig: baselineRig, startDate: baseStart, endDate: baseEnd },
              API_ROLLUP.HOUR
            )
              .then((result) => result)
              .catch(() => null);
          }
          return null;
        }
      }
    ]
  });
  const baselineEquipmentQueries = useQueries({
    queries: [
      {
        queryKey: [
          'baseline',
          'equipment',
          'main',
          { selectedRig: selectedRig, startDate: mainStart, endDate: mainEnd }
        ],
        enabled:
          !!selectedRig &&
          !!mainStart &&
          !!mainEnd &&
          !!visibleSection.includes(BASELINE_SUB_SECTIONS.EQUIPMENT),
        placeholderData: null,
        queryFn: ({ queryKey, signal }) => {
          return fetchEquipmentData(signal, queryKey[3], API_ROLLUP.HOUR)
            .then((result) => {
              return { ...result.clarifyData, DWEnergy: result.clarifyData.DWEnergyCons };
            })
            .catch(() => null);
        }
      },
      {
        queryKey: ['baseline', 'equipment', 'base', { selectedBaseline: selectedBaseline }],
        enabled:
          !!selectedBaseline &&
          !!baselineRig &&
          !!visibleSection.includes(BASELINE_SUB_SECTIONS.EQUIPMENT),
        placeholderData: null,
        queryFn: ({ signal }) => {
          if (baselineRig) {
            return fetchEquipmentData(
              signal,
              { selectedRig: baselineRig, startDate: baseStart, endDate: baseEnd },
              API_ROLLUP.HOUR
            )
              .then((result) => {
                return { ...result.clarifyData, DWEnergy: result.clarifyData.DWEnergyCons };
              })
              .catch(() => null);
          }
          return null;
        }
      }
    ]
  });
  const baselinePowerMgmtQueries = useQueries({
    queries: [
      {
        queryKey: [
          'baseline',
          'powerMgmt',
          'main',
          { selectedRig: selectedRig, startDate: mainStart, endDate: mainEnd }
        ],
        enabled:
          !!selectedRig &&
          !!mainStart &&
          !!mainEnd &&
          !!visibleSection.includes(BASELINE_SUB_SECTIONS.POWER_MGMT),
        placeholderData: null,
        queryFn: ({ queryKey, signal }) => {
          return fetchPowerMgmtData(signal, queryKey[3], API_ROLLUP.HOUR)
            .then((result) => result)
            .catch(() => null);
        }
      },
      {
        queryKey: ['baseline', 'powerMgmt', 'base', { selectedBaseline: selectedBaseline }],
        enabled:
          !!selectedBaseline &&
          !!baselineRig &&
          !!visibleSection.includes(BASELINE_SUB_SECTIONS.POWER_MGMT),
        placeholderData: null,
        queryFn: ({ signal }) => {
          if (baselineRig) {
            return fetchPowerMgmtData(
              signal,
              { selectedRig: baselineRig, startDate: baseStart, endDate: baseEnd },
              API_ROLLUP.HOUR
            )
              .then((result) => result)
              .catch(() => null);
          }
          return null;
        }
      }
    ]
  });
  const baselineAnalyticsQueries = useQueries({
    queries: [
      {
        queryKey: [
          'baseline',
          'analytics',
          'main',
          { selectedRig: selectedRig, startDate: mainStart, endDate: mainEnd }
        ],
        enabled:
          !!selectedRig &&
          !!mainStart &&
          !!mainEnd &&
          !!visibleSection.includes(BASELINE_SUB_SECTIONS.ANALYTICS),
        placeholderData: null,
        queryFn: ({ queryKey, signal }) => {
          return fetchAnalyticsData(signal, queryKey[3], API_ROLLUP.MIN)
            .then((result) => result)
            .catch(() => null);
        }
      },
      {
        queryKey: ['baseline', 'analytics', 'base', { selectedBaseline: selectedBaseline }],
        enabled:
          !!selectedBaseline &&
          !!baselineRig &&
          !!visibleSection.includes(BASELINE_SUB_SECTIONS.ANALYTICS),
        placeholderData: null,
        queryFn: ({ signal }) => {
          if (baselineRig) {
            return fetchAnalyticsData(
              signal,
              { selectedRig: baselineRig, startDate: baseStart, endDate: baseEnd },
              API_ROLLUP.MIN
            )
              .then((result) => result)
              .catch(() => null);
          }
          return null;
        }
      }
    ]
  });

  const resetBaselinePageData = useCallback(async () => {
    await queryClient.invalidateQueries({ queryKey: ['baseline', 'overview'] });
    await queryClient.invalidateQueries({ queryKey: ['baseline', 'keyFig'] });
    await queryClient.invalidateQueries({ queryKey: ['baseline', 'drilling'] });
    await queryClient.invalidateQueries({ queryKey: ['baseline', 'equipment'] });
    await queryClient.invalidateQueries({ queryKey: ['baseline', 'powerMgmt'] });
    await queryClient.invalidateQueries({ queryKey: ['baseline', 'analytics'] });
  }, [queryClient]);

  return {
    baselineOverviewQueries,
    baselineDrillingQueries,
    baselineEquipmentQueries,
    baselinePowerMgmtQueries,
    baselineAnalyticsQueries,
    baselineKeyFiguresQueries,
    resetBaselinePageData
  };
};
