import React, { useCallback } from 'react';
import { DISPLAY_UNIT, UNIT_LIMIT } from '@constants';
import { formatNumberReadable, parseNumberToSignificant } from '@helpers';
import { useSelector } from 'react-redux';
import { getDisplayUnit } from '@store/features';
import { Typography } from '@esgian/esgianui';

export const useDisplayUnit = () => {
  const displayUnit = useSelector(getDisplayUnit);

  const getUnitTitle = useCallback(
    (val, unit = undefined) => {
      let useUnit = unit ?? displayUnit;
      let title = '';
      let converted = false;
      if (useUnit === DISPLAY_UNIT.ENERGY) {
        title = 'kWh';
        if (val > UNIT_LIMIT.ENERGY) {
          title = 'MWh';
          converted = true;
        }
      }
      if (useUnit === DISPLAY_UNIT.CO2) {
        title = 'mt';
        if (val < UNIT_LIMIT.CO2) {
          title = 'kg';
          converted = true;
        }
      }
      if (useUnit === DISPLAY_UNIT.FUEL) {
        title = 'mt';
        if (val < UNIT_LIMIT.FUEL) {
          title = 'kg';
          converted = true;
        }
      }
      if (useUnit === DISPLAY_UNIT.NOX) {
        title = 'kg';
      }
      return { converted: converted, title: title };
    },
    [displayUnit]
  );

  const valueWithUnit = useCallback((val, unit, asObj = false) => {
    if (isNaN(val)) {
      return `-`;
    }
    let title = '';
    let value = val;
    if (unit === DISPLAY_UNIT.ENERGY) {
      title = 'kWh';
      if (val > UNIT_LIMIT.ENERGY) {
        value = value / 1000;
        title = 'MWh';
      }
    }
    if (unit === DISPLAY_UNIT.CO2) {
      title = 'mt';
      if (val < UNIT_LIMIT.CO2) {
        value = value * 1000;
        title = 'kg';
      }
    }
    if (unit === DISPLAY_UNIT.FUEL) {
      title = 'mt';
      if (val < UNIT_LIMIT.FUEL) {
        value = value * 1000;
        title = 'kg';
      }
    }
    if (unit === DISPLAY_UNIT.NOX) {
      title = 'kg';
    }

    if (asObj) {
      return { value: value, title: title };
    }

    value = parseNumberToSignificant(value);

    return `${formatNumberReadable(value)} ${title}`;
  }, []);

  const convertUnitValue = useCallback((val, unit, readable = true) => {
    let value = val;
    if (unit === DISPLAY_UNIT.ENERGY) {
      value = value / 1000;
    }
    if (unit === DISPLAY_UNIT.CO2) {
      value = value * 1000;
    }
    if (unit === DISPLAY_UNIT.FUEL) {
      value = value * 1000;
    }
    if (readable) {
      return parseNumberToSignificant(value);
    }
    return value;
  }, []);

  const getKpiDisplayText = useCallback(
    (unit, totOrAvg, avgOf = null) => {
      let unitDisplay = displayUnit.toLowerCase();
      let unitUsage = 'consumption';
      if (displayUnit === DISPLAY_UNIT.CO2) {
        unitUsage = 'emissions';
        unitDisplay = (
          <>
            CO<sub>2</sub>e
          </>
        );
      } else if (displayUnit === DISPLAY_UNIT.NOX) {
        unitUsage = 'emissions';
        unitDisplay = (
          <>
            NO<sub>x</sub>
          </>
        );
      }
      return (
        <Typography variant={'caption'} color={'text.disabled'}>
          {totOrAvg} {unitDisplay} {unitUsage}
          {avgOf ? '/' + avgOf : ''} ({unit ?? '-'})
        </Typography>
      );
    },
    [displayUnit]
  );

  return { getUnitTitle, valueWithUnit, convertUnitValue, getKpiDisplayText, displayUnit };
};
