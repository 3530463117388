import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ArrowForwardIcon, Button, Paper, Stack, Typography } from '@esgian/esgianui';
import { DISPLAY_UNIT, SUB_SECTIONS, UTC_TIMESTAMP } from '@constants';
import { useUpdateSection, useDisplayUnit, useSeriesBuilder } from '@hooks';
import { OperationsPeriodicOverviewChart } from '@components/Charts';
import { useSelector } from 'react-redux';
import { getDisplayUnit } from '@store/features';
import moment from 'moment';

function OperationsPeriodicOverview({ loading, overviewData }) {
  const { updateMainPageSection } = useUpdateSection();
  const { getUnitTitle } = useDisplayUnit();
  const { getSeriesData } = useSeriesBuilder();
  const displayUnit = useSelector(getDisplayUnit);

  const { categories, series, title } = useMemo(() => {
    if (!overviewData) return {};
    const {
      clarifyData: {
        AccomEnergy,
        AccomCO2,
        AccomFuel,
        AccomNOx,
        DrillingEnergy,
        DrillingCO2,
        DrillingFuel,
        DrillingNOx
      }
    } = overviewData;
    let accommodation = AccomCO2;
    let drilling = DrillingCO2;

    if (displayUnit === DISPLAY_UNIT.ENERGY) {
      accommodation = AccomEnergy;
      drilling = DrillingEnergy;
    }
    if (displayUnit === DISPLAY_UNIT.FUEL) {
      accommodation = AccomFuel;
      drilling = DrillingFuel;
    }
    if (displayUnit === DISPLAY_UNIT.NOX) {
      accommodation = AccomNOx;
      drilling = DrillingNOx;
    }
    const {
      max: maxAccom,
      defaultArray: defaultAccom,
      convertedArray: convertedAccom
    } = getSeriesData(accommodation, displayUnit);
    const {
      max: maxDrilling,
      defaultArray: defaultDrilling,
      convertedArray: convertedDrilling
    } = getSeriesData(drilling, displayUnit);

    let max = maxAccom > maxDrilling ? maxAccom : maxDrilling;
    const { title, converted } = getUnitTitle(max);

    return {
      title: title,
      categories: Object.keys(accommodation).map((key) =>
        moment.parseZone(key).format(UTC_TIMESTAMP)
      ),
      series: [
        {
          name: 'Hotel and utilities',
          type: 'bar',
          data: converted ? convertedAccom : defaultAccom
        },
        {
          name: 'Drilling',
          type: 'bar',
          data: converted ? convertedDrilling : defaultDrilling
        }
      ]
    };
  }, [overviewData, displayUnit]);
  return (
    <Paper sx={{ p: 2 }}>
      <Stack sx={{ mb: 2 }}>
        <Typography variant={'h6'}>Operations Periodic overview</Typography>
      </Stack>
      <OperationsPeriodicOverviewChart
        unitTitle={title}
        loading={loading}
        categories={categories}
        series={series}
      />
      <Stack direction={'row'} justifyContent={'flex-end'}>
        <Button
          onClick={() => updateMainPageSection(SUB_SECTIONS.DRILLING_ACTIVITIES)}
          endIcon={<ArrowForwardIcon />}
          variant={'outlined'}>
          See Drilling activities page
        </Button>
      </Stack>
    </Paper>
  );
}

OperationsPeriodicOverview.propTypes = {
  loading: PropTypes.bool,
  overviewData: PropTypes.object
};

OperationsPeriodicOverview.defaultProps = {
  overviewData: null,
  loading: false
};

export default OperationsPeriodicOverview;
