/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState } from 'react';
import { Grid, Paper, Stack, Typography } from '@esgian/esgianui';
import { PercentageLoadPerEngineChart } from '@components/Charts';
import EnginePercentageLoadChart from '../../Charts/EnginePercentageLoadChart';
import PowerDetailsWrapper from '@components/Sections/PowerMgmtSection/PowerDetailsWrapper';
import { useTimezone } from '@hooks/useTimezone';
import { useMainPageQueries } from '@hooks/usePageQueries/useMainPageQueries';

function PowerMgmtSection() {
  const [selectedDate, setSelectedDate] = useState(null);
  const { selectedTimeZone } = useTimezone();
  const { rigPowerMgmtQuery } = useMainPageQueries();
  useEffect(() => {
    if (!rigPowerMgmtQuery.data) return;
    const {
      clarifyData: { EngOn = {} }
    } = rigPowerMgmtQuery.data;
    setSelectedDate({ index: 0, date: Object.keys(EngOn || {})?.[0], isLast: false });
  }, [rigPowerMgmtQuery.data, selectedTimeZone]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={3.5}>
        <Paper>
          <Stack sx={{ pl: 2, pt: 2 }}>
            <Typography variant={'h6'}>Percentage load per engine</Typography>
            <Typography variant={'body2'}>Average load and SFOC</Typography>
          </Stack>
          <PercentageLoadPerEngineChart
            loading={rigPowerMgmtQuery.isFetching}
            powerMgmtData={rigPowerMgmtQuery.data}
          />
        </Paper>
      </Grid>
      <Grid item xs={8.5}>
        <Paper sx={{ pl: 2, pt: 2, pr: 2 }}>
          <Stack>
            <Typography variant={'h6'}>Engine percentage load</Typography>
            <Typography variant={'body2'}>Average per day</Typography>
            <EnginePercentageLoadChart
              loading={rigPowerMgmtQuery.isFetching}
              powerMgmtData={rigPowerMgmtQuery.data}
              setSelectedDate={setSelectedDate}
            />
          </Stack>
          <PowerDetailsWrapper loading={rigPowerMgmtQuery.isFetching} selectedDate={selectedDate} />
        </Paper>
      </Grid>
    </Grid>
  );
}
PowerMgmtSection.propTypes = {};

PowerMgmtSection.defaultProps = {};

export default PowerMgmtSection;
